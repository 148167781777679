import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { BrowserRouter } from 'react-router-dom'
import './index.css';
// import GoogleTranslate from './utils/GoogleTranslate.jsx'
 
createRoot(document.getElementById('root')!).render(<BrowserRouter>
    {/* <GoogleTranslate
  pageLanguage="en"
  includedLanguages="hi,bn,as,gu,ml,mr,ne,or,pa,sa,sd,ta,te,ur"
  layout="HORIZONTAL"
  autoDisplay= "false"
  style={{ backgroundColor: 'transparent', padding: '5px' }}
/> */}
<App />
</BrowserRouter>)
