import axios from 'axios';
import { useEffect, useState } from 'react';
import { baseURL } from '../../config';
import moment from 'moment';

interface Creator {
  _id: string;
  fullName: string;
  profilePic: string;
}

interface Submission {
  _id: string;
  link: string;
  description: string;
  submittedBy: Creator;
  submittedAt: string;
}

interface Project {
  _id: string;
  title: string;
  description: string;
  difficulty: string;
  deadline: string;
  learningSkills: string[];
  submissionLink: Submission[];
  banner: string;
  task: string;
  prerequisites: string;
  submissionMethod: string;
  creator: Creator;
  createdAt: string;
}

const Project = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${baseURL}/project/user/${userId}`);
      setProjects(response.data.projects.docs);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="space-y-8 p-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">Project Overview</h2>
      {projects && projects.length > 0 ? (
  projects.map((project) => (
    <div key={project._id} className="bg-white rounded-lg shadow-lg p-6 space-y-4">
      <h3 className="text-2xl font-semibold text-gray-900">{project.title}</h3>
      <p className="text-gray-700 break-words w-full max-w-lg">{project.description}</p>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <strong className="text-gray-600">Task:</strong>
          <p className="text-gray-700">{project.task}</p>
        </div>
        <div>
          <strong className="text-gray-600">Deadline:</strong>
          <p className="text-gray-700">{moment(project.deadline).format('D MMM, YYYY h:mm A')}</p>
        </div>
        <div>
          <strong className="text-gray-600">Difficulty:</strong>
          <p className="text-gray-700">{project.difficulty}</p>
        </div>
        <div>
          <strong className="text-gray-600">Prerequisites:</strong>
          <p className="text-gray-700">{project.prerequisites}</p>
        </div>
        <div>
          <strong className="text-gray-600">Submission Method:</strong>
          <p className="text-gray-700">{project.submissionMethod}</p>
        </div>
        <div>
          <strong className="text-gray-600">Learning Skills:</strong>
          <p className="text-gray-700">
            {project.learningSkills?.length
              ? project.learningSkills.join(', ')
              : 'None specified'}
          </p>
        </div>
      </div>

      {/* Submission Links */}
      <div>
        <strong className="text-gray-600">Submissions:</strong>
        <div className="space-y-2">
          {project.submissionLink?.length ? (
            project.submissionLink.map((submission) => (
              <div key={submission._id} className="space-y-1">
                <a
                  href={submission.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {submission.description}
                </a>
                <p className="text-sm text-gray-500">
                  Submitted By: {submission.submittedBy.fullName}
                </p>
                <p className="text-sm text-gray-500">
                  Submitted At: {new Date(submission.submittedAt).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p className="text-gray-700">No submissions available</p>
          )}
        </div>
      </div>

      {/* Creator Info */}
      <div>
        <strong className="text-gray-600">Created By:</strong>
        <div className="flex items-center space-x-4">
          <img
            src={project.creator?.profilePic || '/placeholder-profile.png'}
            alt={project.creator?.fullName}
            className="w-12 h-12 rounded-full"
          />
          <p className="text-gray-700">{project.creator?.fullName}</p>
        </div>
      </div>
    </div>
  ))
) : (
  <p className="text-center text-gray-500 text-xl mt-8">No projects started yet.</p>
)}

    </div>
  );
};

export default Project;
