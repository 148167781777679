import { useState } from 'react';
import { type MenuItem } from './types';
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/favicon.png';

interface MobileNavProps {
  menuItems: MenuItem[];
  onItemClick: (item: MenuItem) => void;
}

export function MobileNav({ menuItems, onItemClick }: MobileNavProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    setShowDropdown((prev) => !prev); // Toggle the dropdown
  };

  const handleNavigateToProjects = () => {
    navigate('/project'); // Navigate to the projects page
    setShowDropdown(false); // Close the dropdown after navigation
  };

  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-40">
      <div className="flex justify-around items-center h-16 relative">
        {menuItems.map((item, index) =>
          index === Math.floor(menuItems.length / 2) ? (
            // Center logo with dropdown
            <div key="logo" className="relative">
              <div
                className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-lg cursor-pointer -mt-12"
                onClick={handleLogoClick}
              >
                <img
                  src={logo}
                  alt="Logo"
                  className="w-10 h-10 rounded-full"
                />
              </div>
              {showDropdown && (
                <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md rounded-md p-2">
                  <button
                    className="text-blue-600 text-sm px-4 py-2 hover:bg-gray-100 rounded-md"
                    onClick={handleNavigateToProjects}
                  >
                    Projects
                  </button>
                </div>
              )}
            </div>
          ) : (
            <a
              key={item.label}
              href={item.href}
              className="flex flex-col items-center justify-center w-16 text-gray-600 hover:text-blue-600"
              onClick={(e) => {
                e.preventDefault();
                onItemClick(item);
              }}
            >
              <item.icon className="w-5 h-5" />
              <span className="text-xs mt-1">{item.label}</span>
            </a>
          )
        )}
      </div>
    </div>
  );
}
