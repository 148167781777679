export interface Question {
    id: string;
    label: string;
    type: 'select' | 'multiselect' | 'textarea' | 'number';
    options?: string[];
    placeholder?: string;
  }

 export const questions:Question[]=[
    {
        id: 'weight',
        label: 'What is your current weight? (in kg)',
        type: 'number',
        placeholder: 'Enter weight in kg'
      },
      {
        id: 'height',
        label: 'What is your height? (in cm)',
        type: 'number',
        placeholder: 'Enter height in cm'
      },
      {
        id: 'dietRating',
        label: 'How would you rate your overall diet?',
        type: 'select',
        options: ['Excellent', 'Good', 'Fair', 'Poor']
      },
      {
        id: 'fruitVegetableServings',
        label: 'How many servings of fruits and vegetables do you consume daily?',
        type: 'number',
        placeholder: 'Enter number of servings'
      },
      {
        id: 'dietPlan',
        label: 'Do you follow any specific diet plan?',
        type: 'select',
        options: [
          'None',
          'Vegetarian',
          'Vegan',
          'Keto',
          'Paleo',
          'Mediterranean',
          'Other'
        ]
      },
      {
        id: 'processedFoodConsumption',
        label: 'How often do you consume processed or fast foods?',
        type: 'select',
        options: [
          'Daily',
          'Several times a week',
          'Once a week',
          'Rarely',
          'Never'
        ]
      },
      {
        id: 'waterIntake',
        label: 'How many glasses of water do you drink daily?',
        type: 'number',
        placeholder: 'Enter number of glasses'
      },
      {
        id: 'otherBeverages',
        label: 'What other beverages do you regularly consume?',
        type: 'multiselect',
        options: [
          'Coffee',
          'Tea',
          'Soft Drinks',
          'Energy Drinks',
          'Alcohol',
          'None'
        ]
      },
      {
        id: 'exerciseFrequency',
        label: 'How often do you exercise?',
        type: 'select',
        options: [
          'Daily',
          '3-5 times a week',
          '1-2 times a week',
          'Rarely',
          'Never'
        ]
      },
      {
        id: 'preferredActivities',
        label: 'What types of physical activities do you enjoy?',
        type: 'multiselect',
        options: [
          'Walking',
          'Running',
          'Swimming',
          'Cycling',
          'Yoga',
          'Weight Training',
          'Team Sports',
          'Other'
        ]
      },
      {
        id: 'overallHealth',
        label: 'How would you rate your overall health?',
        type: 'select',
        options: ['Excellent', 'Good', 'Fair', 'Poor']
      },
      {
        id: 'preExistingConditions',
        label: 'Do you have any pre-existing health conditions? If yes, please specify:',
        type: 'textarea',
        placeholder: 'List any medical conditions...'
      },
      {
        id: 'sleepHours',
        label: 'On average, how many hours of sleep do you get per night?',
        type: 'number',
        placeholder: 'Enter hours of sleep'
      },
      {
        id: 'healthGoals',
        label: 'What are your primary health goals?',
        type: 'textarea',
        placeholder: 'Describe your health goals...'
      },
      {
        id: 'stressFrequency',
        label: 'How often do you feel stressed?',
        type: 'select',
        options: [
          'Rarely',
          'Sometimes',
          'Often',
          'Very often'
        ]
      },
      {
        id: 'stressManagement',
        label: 'How do you manage stress?',
        type: 'multiselect',
        options: [
          'Exercise',
          'Meditation',
          'Reading',
          'Music',
          'Therapy',
          'Other'
        ]
      },
      {
        id: 'mentalWellbeingPractice',
        label: 'Do you practice any mental wellbeing techniques?',
        type: 'multiselect',
        options: [
          'Meditation',
          'Mindfulness',
          'Yoga',
          'Breathing exercises',
          'Journaling',
          'None'
        ]
      }
 ]