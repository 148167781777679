import { useEffect, useState } from 'react';
import { baseURL } from '../../../config';
import axios from 'axios';
import './PersonalDetails.css';
import { SkyBackground } from './SkyBackground';
import { ProfileSection } from './ProfileSection';
import { PeopleSection } from './utils/PeopleSection';
import { getTimeOfDay, type TimeOfDay } from './utils/timeUtils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

interface User {
  fullName: string;
  dateOfBirth: string;
  emailAddress: string;
  phoneNumber: string;
  cityAndCountry: string;
  gender: string;
  password: string;
  centerCode: string;
}

type Person = {
  personalDetails:{
    fullName: string;
  }
  profilePic: string;
};

export function PersonalDetails() {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<User>({} as User);
  const [profile, setProfile] = useState<string>('');
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: -10, y: 20 });
  const [people, setPeople] = useState<Person[]>([]);
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay>('morning');
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getDetails();
    updateCelestialPosition();
    const interval = setInterval(updateCelestialPosition, 60000);
    fetchPeople();
    return () => clearInterval(interval);
  }, [userId]);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.personalDetails);
      setProfile(response.data.profilePic);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPeople = async () => {
const response=await axios.get(`${baseURL}/user`)
const mockPeople=response.data
    setPeople(mockPeople);
  };

  const updateCelestialPosition = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const currentTimeOfDay = getTimeOfDay(hours);
    setTimeOfDay(currentTimeOfDay);
    const totalMinutes = hours * 60 + minutes;
    const progress = totalMinutes / (24 * 60);
    const angle = Math.PI * progress;
    const x = 50 - Math.cos(angle) * 60;
    const y = 15 + Math.sin(angle) * 10;
    setPosition({ x, y });
  };

  return (
    <section className={`relative ${timeOfDay} min-h-[80vh] mb-3 rounded-3xl`}>
      <div className="container mx-auto w-[90%]">
        {/* Sky Section */}
        <div className="relative h-[120px] mb-8">
          <SkyBackground timeOfDay={timeOfDay} position={position} />
        </div>

        {/* Content Section */}
        {isMobile ? (
          <Swiper spaceBetween={20} slidesPerView={1} modules={[Autoplay]} loop={false} autoplay={{delay: 3000,disableOnInteraction: true}}>
            <SwiperSlide>
              <div className="bg-white/20 backdrop-blur-lg mb-2 rounded-3xl min-h-[40vh] p-6 shadow-xl">
                <ProfileSection profile={profile} fullName={data.fullName} dateOfBirth={data.dateOfBirth} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white/20 backdrop-blur-lg min-h-[66vh] rounded-3xl p-6 shadow-xl">
                <PeopleSection people={people} />
              </div>
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pb-8">
            <div className="bg-white/20 backdrop-blur-lg rounded-3xl p-6 shadow-xl">
              <ProfileSection profile={profile} fullName={data.fullName} dateOfBirth={data.dateOfBirth} />
            </div>
            <div className="bg-white/20 backdrop-blur-lg rounded-3xl p-6 shadow-xl">
              <PeopleSection people={people} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
