import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface RadarChartProps {
  data: Record<string, number>;
  labels: string[];
  colors: string[]; // Array of colors for each label
}

export function RadarChart({ data, labels, colors }: RadarChartProps) {
  const chartData = {
    labels,
    datasets: [
      {
        label:"Score",
        data: Object.values(data),
        backgroundColor: `${colors[0]}33`, // Semi-transparent version of the first color
        borderColor: colors[0], // Use the first color for the border
        borderWidth: 1,
        pointBackgroundColor: colors, // Array of colors for each point
        pointRadius: 3, // Adjust point radius
        pointHoverRadius: 5, // Adjust hover radius
      },
    ],
  };

  const options = {
    scales: {
      r: {
        min: 0,
        max: 100,
        ticks: { stepSize: 20 },
      },
    },
    maintainAspectRatio: true, // Ensure the aspect ratio is maintained
  };

  return (
    <div className="w-full h-[300px] sm:h-[450px] md:h-[400px] lg:h-[500px] lg:ml-16  ">
      <Radar data={chartData} options={options} />
    </div>
  );
}
