import { type MenuItem } from './types';
import { Tooltip } from './ToolTip';
import { useNavigate } from 'react-router-dom';

interface DesktopSidebarProps {
  menuItems: MenuItem[];
  logo: string;
  onItemClick: (item: MenuItem) => void;
}

export function DesktopSidebar({ menuItems, logo, onItemClick }: DesktopSidebarProps) {
  const navigate = useNavigate();
  return (
    <div className="hidden md:block fixed top-0 left-0 h-screen w-16 bg-white border-r border-gray-200 z-40">
      <div className="flex flex-col items-center pt-8 space-y-6">
        <img src={logo} alt="Logo" className="w-[54px] h-[54px] cursor-pointer" onClick={()=>navigate('/')}/>
        {menuItems.map((item) => (
          <Tooltip key={item.label} content={item.label} position="right">
            <a
              href={item.href}
              className="flex items-center justify-center w-10 h-10 text-gray-600 hover:bg-gray-50 rounded-lg transition-colors duration-150"
              onClick={(e) => {
                e.preventDefault();
                onItemClick(item);
              }}
            >
              <item.icon className="w-5 h-5" />
            </a>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}