

interface CriteriaCardProps {
  number: string
  iconSrc: string
  description: string
  colorClass: string
}

export function CriteriaCard({ number, iconSrc, description, colorClass }: CriteriaCardProps) {
  return (
<div className="flex flex-col items-center text-center p-4 md:p-6 lg:p-8">
  <div
    className={`${colorClass} rounded-full w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 flex items-center justify-center mb-4`}
  >
    <img
      src={iconSrc || "/placeholder.svg"}
      alt={`Criteria ${number}`}
      className="object-contain w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20"
    />
  </div>
  <p className="text-sm sm:text-base lg:text-lg text-gray-700 px-2">
    {description}
  </p>
</div>

  )
}

