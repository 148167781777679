import React, { useState } from 'react';
import { ArrowLeft, ArrowRight, Send, Loader } from 'lucide-react'; // Added Loader icon
import { RoadmapQuestion } from './RoadmapQuestions';
import { questions } from './Questions';
import { baseURL } from "../../../config";
import axios from 'axios';

interface RoadmapFormProps {
  onSubmit: (answers: string[]) => void;
  onClose: () => void;
}

interface RoadmapData {
  shortTermGoals: string;
}

export function RoadmapForm({ onSubmit, onClose }: RoadmapFormProps) {
  const [answers, setAnswers] = useState<string[]>(Array(questions.length).fill(''));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [, setRoadmapData] = useState<RoadmapData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Added loading state

  const userId = localStorage.getItem('userId');
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const handleAnswerChange = (value: string) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = value;
    setAnswers(updatedAnswers);
  };

  const validateAnswers = () => {
    return answers.every((answer) => answer.trim() !== '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!validateAnswers()) {
      setError('Please answer all questions before submitting.');
      return;
    }

    setLoading(true); // Start loading
    try {
      const { data } = await axios.post(`${baseURL}/user/roadmap`, answers);
      setRoadmapData(data);

      if (userId && data) {
        await axios.put(`${baseURL}/user/submit-data/${userId}`, {
          personalVisionAndGoals: {
            shortTermGoals: data,
          },
        });
      }

      onSubmit(answers);
      window.location.reload()
    } catch (err) {
      console.error('Error submitting data:', err);
      setError('An error occurred while submitting. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const goToPreviousQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.max(0, prev - 1));
  };

  const goToNextQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.min(questions.length - 1, prev + 1));
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      aria-labelledby="roadmap-form-title"
    >
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full transition-transform transform scale-100">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2
              id="roadmap-form-title"
              className="text-2xl font-semibold"
            >
              Growth Roadmap Assessment
              <span className="text-sm text-gray-500 ml-2">
                Question {currentQuestionIndex + 1} of {questions.length}
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              ×
            </button>
          </div>

          {error && (
            <p className="text-sm text-red-600 mb-4">{error}</p>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div
              className="transition-opacity duration-200 ease-in-out"
              key={currentQuestionIndex}
            >
              <RoadmapQuestion
                question={questions[currentQuestionIndex]}
                answer={answers[currentQuestionIndex]}
                onChange={handleAnswerChange}
              />
            </div>

            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={goToPreviousQuestion}
                disabled={currentQuestionIndex === 0}
                className={`flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 transition-opacity duration-200 ${
                  currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                }`}
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Previous
              </button>

              {isLastQuestion ? (
                <button
                  type="submit"
                  disabled={loading} // Disable button while loading
                  className={`flex items-center px-4 py-2 text-sm font-medium text-white rounded-md transition-all duration-200 ${
                    loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
                  }`}
                >
                  {loading ? (
                    <>
                      <Loader className="w-4 h-4 mr-2 animate-spin" />
                      <span>Submitting...</span>
                    </>
                  ) : (
                    <>
                      <span>Submit</span>
                      <Send className="w-4 h-4 ml-2" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={goToNextQuestion}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
                >
                  <span>Next</span>
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              )}
            </div>
          </form>

          <div className="mt-4 flex justify-center">
            <div className="flex space-x-2">
              {questions.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === currentQuestionIndex ? 'bg-green-600' : 'bg-gray-300'
                  }`}
                  aria-label={`Question ${index + 1} of ${questions.length}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
