import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowRight, Send } from 'lucide-react';
import { AssessmentFormProps, CoreSkillsQuestion } from './type';
import { iqQuestions, coreSkillsQuestions } from './data';
import { AssessmentResult, TestResult, TestType } from './type';
import { AssessmentProgress } from './Progress';
import { QuestionIndicators } from './Indicators';
import { baseURL } from '../../../config';

export function AssessmentForm({ onSubmit, onClose, type: initialType }: AssessmentFormProps) {
  const userId = localStorage.getItem('userId');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [type, setType] = useState<TestType>(initialType);
  const [answers, setAnswers] = useState<Record<TestType, string[]>>({
    iq: Array(iqQuestions.length).fill(undefined),
    core: Array(coreSkillsQuestions.length).fill(undefined)
  });
  const [timer, setTimer] = useState(45); // 45 seconds timer
  const [isBlinking, setIsBlinking] = useState(false); // For blinking effect

  const questions = type === 'iq' ? iqQuestions : coreSkillsQuestions;
  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const hasCompletedCurrent = answers[type].every(answer => answer !== undefined);
  const hasCompletedBoth = 
    answers.iq.every(answer => answer !== undefined) && 
    answers.core.every(answer => answer !== undefined);

  const handleAnswer = (answer: string) => {
    const newAnswers = { ...answers };
    newAnswers[type] = [...newAnswers[type]];
    newAnswers[type][currentQuestionIndex] = answer;
    setAnswers(newAnswers);
  };

  const calculateScores = (testType: TestType): TestResult => {
    const testQuestions = testType === 'iq' ? iqQuestions : coreSkillsQuestions;
    const testAnswers = answers[testType];
    let totalScore = 0;
    const categoryScores: Record<string, number> = {};

    testAnswers.forEach((answer, index) => {
      if (answer === testQuestions[index].correctAnswer) {
        totalScore += 1;
        if (testType === 'core') {
          const category = (testQuestions[index] as CoreSkillsQuestion).category;
          categoryScores[category] = (categoryScores[category] || 0) + 1;
        }
      }
    });

    return {
      totalScore: Math.round((totalScore / testQuestions.length) * 100),
      categoryScores: testType === 'core' ? categoryScores : undefined,
    };
  };

  const saveAssessmentData = async (result: AssessmentResult) => {
    try {
      const payload = {
        iqAssessment: {
          iqScore: result.iq.totalScore,
          coreSkillsData: result.core.categoryScores,
        },
      };
      await axios.put(`${baseURL}/user/submit-data/${userId}`, payload);
      // Reload the page after successfully saving the data
      window.location.reload();
    } catch (error) {
      console.error('Error saving assessment data:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (hasCompletedCurrent && !hasCompletedBoth) {
      // Switch to the other test
      const nextType = type === 'iq' ? 'core' : 'iq';
      setType(nextType);
      setCurrentQuestionIndex(0);
    } else if (hasCompletedBoth) {
      // Submit both test results
      const result: AssessmentResult = {
        iq: calculateScores('iq'),
        core: calculateScores('core')
      };

      await saveAssessmentData(result); // Save data to the database
      onSubmit(result);
    }
  };

  const handleNext = () => {
    if (answers[type][currentQuestionIndex]) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } 
  };

  // const handlePrevious = () => {
  //   if (currentQuestionIndex > 0) {
  //     setCurrentQuestionIndex(prev => prev - 1);
  //   }
  // };

// Timer logic
useEffect(() => {
  // Reset the timer when the current question changes
  setTimer(45);

  const interval = setInterval(() => {
    setTimer((prev) => {
      if (prev === 1) {
        clearInterval(interval);
        
        // Record empty response for the current question and navigate
        const newAnswers = { ...answers };
        newAnswers[type] = [...newAnswers[type]];
        newAnswers[type][currentQuestionIndex] = ""; // Save no response
        setAnswers(newAnswers);

        if (!isLastQuestion) {
          setCurrentQuestionIndex((prev) => prev + 1);
        }
        
        return 0; // Stop the timer
      }
      return prev - 1;
    });
  }, 1000);

  return () => clearInterval(interval);
}, [currentQuestionIndex]);

  

  // Blink effect when time is about to run out
  useEffect(() => {
    if (timer <= 10 && timer > 0) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }
  }, [timer]);

  return (
    <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold">
              {type === 'iq' ? 'IQ Assessment' : 'Core Skills Assessment'}
              {hasCompletedBoth && ' - Final Submission'}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <p className="font-medium">
                {type === 'core' && (
                  <span className="text-yellow-600">
                    [{(currentQuestion as CoreSkillsQuestion).category}]
                  </span>
                )}
                {' '}
                {currentQuestion.question}
              </p>
              <div className="space-y-2">
                {currentQuestion.options.map((option) => (
                  <label key={option} className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name={`question-${currentQuestionIndex}`}
                      value={option}
                      checked={answers[type][currentQuestionIndex] === option}
                      onChange={(e) => handleAnswer(e.target.value)}
                      className="text-yellow-600 focus:ring-yellow-500"
                    />
                    <span>{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-between pt-4">
              {/* <button
                type="button"
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
                  currentQuestionIndex === 0
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-white bg-yellow-600 hover:bg-yellow-700'
                }`}
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Previous
              </button> */}

              <div className="flex space-x-2">
                {hasCompletedCurrent && (
                  <button
                    type="submit"
                    className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
                  >
                    {hasCompletedBoth ? 'Submit Both Tests' : `Continue to ${type === 'iq' ? 'Core Skills' : 'IQ'} Test`}
                    <Send className="w-4 h-4 ml-2" />
                  </button>
                )}

                {!isLastQuestion && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`flex items-center px-4 py-2 text-sm font-medium text-white ${isBlinking ? 'bg-red-600' : 'bg-yellow-600'} rounded-md hover:bg-yellow-700`}
                  >
                    Next
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </button>
                )}
              </div>
            </div>
          </form>

          <AssessmentProgress
            type={type}
            answers={answers}
            totalQuestions={{
              iq: iqQuestions.length,
              core: coreSkillsQuestions.length
            }}
          />

          <QuestionIndicators 
            totalQuestions={questions.length}
            currentIndex={currentQuestionIndex}
          />

          <div className="text-center mt-4">
            <p className={`text-xl font-semibold ${isBlinking ? 'text-red-600' : 'text-black'}`}>
              Time Left: {timer}s
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
