import { ChevronRightIcon } from "@heroicons/react/24/solid"

interface ProcessStepProps {
  title: string
  onClick: () => void
}

export function ProcessStep({ title, onClick }: ProcessStepProps) {
  return (
<button
  className="w-full sm:w-auto p-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-lg shadow-md flex justify-between items-center transition-all duration-300 hover:shadow-lg hover:from-purple-700 hover:to-indigo-700 md:p-6 lg:p-2"
  onClick={onClick}
>
  <span className="text-base sm:text-lg md:text-xl font-medium">{title}</span>
  <ChevronRightIcon className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8" />
</button>

  )
}

