'use client';
import React, { useEffect, useState } from 'react';
import { Button } from "../components/ui/button";

const Page: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [loading, setLoading] = useState(true); // State to manage loading


  useEffect(() => {
    if (typeof window === 'undefined') return; // Ensure this runs on the client-side

    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get('razorpay_payment_link_status');

    console.log('URL Query:', window.location.search); // Debugging
    console.log('Payment Status:', paymentStatus); // Debugging

    if (!paymentStatus) {
      setErrorMessage('Missing payment status.');
      setLoading(false);
      return;
    }

    if (paymentStatus === 'paid') {
      setIsPaymentSuccessful(true);
    } else {
      setErrorMessage('Payment status is not "paid" or there was an issue.');
    }

    setLoading(false); // Mark loading as complete
  }, []);

  const handleSaveUserData = async () => {
    try {
 window.location.href='https://www.swanirbhar.org.in/signin'
    } catch (error) {
      console.error('Save User Data Error:', error); // Log the error
      setErrorMessage('Failed to save user data. Please try again.');
    }
  };

  const handleGoBackToForm = () => {
   window.location.href='https://onboard.swanirbhar.org.in'; // Redirect back to the form page
  };

  if (loading) {
    return <div className="text-gray-500">Loading...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6 text-center">
      {isPaymentSuccessful ? (
        <div>
          <h2 className="text-2xl font-bold text-green-600">
            Congratulations! Payment Successful 🎉
          </h2>
          <Button className="mt-4" onClick={handleSaveUserData}>
            To Login Page
          </Button>
        </div>
      ) : (
        <div>
          <h2 className="text-2xl font-bold text-red-600">
            Payment Unsuccessful or Incomplete
          </h2>
          <Button className="mt-4" onClick={handleGoBackToForm}>
            Back to Form
          </Button>
          {errorMessage && <p className="mt-2 text-sm text-red-500">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default Page;
