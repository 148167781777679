import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";

export default function Team() {
  return (
    <div className="p-8">
               <div className="shadow-lg bg-white py-5 px-6 flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
                <h1 className="text-2xl font-semibold text-gray-800">Team</h1>
                <Breadcrumb className="text-gray-600">
                    <Breadcrumb.Item href="/dashboard">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="font-bold text-gray-800">
                        Team
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
      <div className="bg-white rounded-lg shadow p-6">
        <p>Team content coming soon...</p>
      </div>
    </div>
  );
}