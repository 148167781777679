import { ReactNode } from 'react';

type Position = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  children: ReactNode;
  content: string;
  position?: Position;
}

export function Tooltip({ children, content, position = 'right' }: TooltipProps) {
  const tooltipClasses: Record<Position, string> = {
    top: 'bottom-full left-1/2 -translate-x-1/2 mb-2',
    right: 'left-full top-1/2 -translate-y-1/2 ml-2',
    bottom: 'top-full left-1/2 -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 -translate-y-1/2 mr-2'
  };

  const arrowClasses: Record<Position, string> = {
    top: 'top-full left-1/2 -translate-x-1/2 border-t-gray-800',
    right: 'right-full top-1/2 -translate-y-1/2 border-r-gray-800',
    bottom: 'bottom-full left-1/2 -translate-x-1/2 border-b-gray-800',
    left: 'left-full top-1/2 -translate-y-1/2 border-l-gray-800'
  };

  return (
    <div className="relative group">
      {children}
      <div className={`absolute ${tooltipClasses[position]} px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-150 whitespace-nowrap z-50`}>
        {content}
        <div className={`absolute ${arrowClasses[position]} border-4 border-transparent`} />
      </div>
    </div>
  );
}