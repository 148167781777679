import axios from "axios";
import { Camera } from "lucide-react";
import { useEffect, useState } from "react";
import { baseURL } from "../../../config";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";

interface Article {
  _id: string;
  title: string;
  category: string;
  content: string;
  coverImage: string;
  createdBy: {
    profilePic?: string;
    fullName: string;
  };
  createdAt: string;
}

export default function InsightHubSection() {
  const [getArticles, setGetArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/article`);
      setGetArticles(response.data.articles);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="insightHub" className="mb-8 px-4 sm:px-6 lg:px-0">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-blue-200 p-3 rounded-full">
          <Camera className="w-6 h-6 text-white" />
        </div>
        <h2 className="text-2xl font-semibold">Blogs</h2>
      </div>
      <div className="mt-8 w-[100%]">
        {isLoading ? (
          <p className="text-center text-blue-500">Loading articles...</p>
        ) : (
          <Carousel
            autoplay
            autoplaySpeed={3000}
            dots={false}
            draggable
            className="w-full md:w-[1100px] mx-auto"
          >
            {getArticles.map((article) => (
              <div key={article._id} className="p-4 w-[1500px]">
                <div className="bg-gray-100 p-2 lg:h-[500px] h-[200px] rounded-lg shadow-lg w-[95%] md:w-[80%] lg:w-full relative">
                  <img
                    className="w-full h-[180px] sm:h-[180px] md:h-[180px] lg:h-[490px] rounded-md"
                    src={article.coverImage}
                    alt={article.title}
                  />
                  <p className="absolute bottom-4 left-4 bg-opacity-50 bg-black/10 text-white text-base lg:text-lg font-semibold px-2 py-1 rounded">
                    {article.title}
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <button className="mt-7" onClick={() => navigate("/blogs")}>
        <p className="text-blue-500 text-base sm:text-lg">Show more blogs</p>
      </button>
    </section>
  );
}
