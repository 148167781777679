/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Brain } from "lucide-react";
import { RadarChart } from "../Charts/RadarChart";
import axios from "axios";
import { baseURL } from '../../../config';
import PersonalityForm from "./PersonalitySectionForm";
import architect from './Mbti-Images/architect.gif'
import logician from './Mbti-Images/logician.svg'
import commander from './Mbti-Images/commander.svg'
import debator from './Mbti-Images/debator.svg'
import advocate from './Mbti-Images/advocate.svg'
import mediator from './Mbti-Images/mediator.svg'
import protagonist from './Mbti-Images/protagonist.svg'
import campaigner from './Mbti-Images/campaigner.svg'
import logistician from './Mbti-Images/logistician.svg'
import defender from './Mbti-Images/defender.svg'
import executive from './Mbti-Images/executive.svg'
import consul from './Mbti-Images/consul.svg'
import virtuoso from './Mbti-Images/virtuoso.svg'
import adventurer from './Mbti-Images/adventurer.svg'
import entrepreneur from './Mbti-Images/entrepreneur.svg'
import entertainer from './Mbti-Images/entertainer.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

// Importing types
import { JudgmentalAssessments } from "../../../utils/types";
import { Autoplay } from "swiper/modules";

interface BigFiveTraits {
  Conscientiousness?: number;
  Openness?: number;
  Extraversion?: number;
  Agreeableness?: number;
  Neuroticism?: number;
}

interface ParsedData {
  bigFive: BigFiveTraits;
  mbtiProfile: string;
  mbtiTraitsList:string[]
}


const MBTI_PROFILES: Record<string, { 
  image: string; 
  label: string; 
  category: string; 
  short_description: string; 
  description: string;
}>={
  INTJ:{
    image:architect,
    label:"Architect",
    category:"Analysts",
    short_description:"Imaginative and strategic thinkers, with a plan for everything.",
    description:"INTJ (Architect) is a personality type with the Introverted, Intuitive, Thinking, and Judging traits. These thoughtful tacticians love perfecting the details of life, applying creativity and rationality to everything they do. Their inner world is often a private, complex one. Logical and quick-witted, INTJs pride themselves on their ability to think for themselves, not to mention their uncanny knack for seeing right through phoniness and hypocrisy. Because their minds are never at rest, these personalities may sometimes struggle to find people who can keep up with their nonstop analysis of everything around them. But when they do find like-minded individuals who appreciate their intensity and depth of thought, INTJs form profound and intellectually stimulating relationships that they deeply treasure. These personality traits can be found in them :  A Pioneering Spirit , A Thirst for Knowledge, Social Frustrations, The Chess Game of Life."
  },
  INTP:{
    image:logician,
    label:"Logician",
    category:"Analysts",
    short_description:"Innovative inventors with an unquenchable thirst for knowledge.",
    description:"INTP (Logician) is a personality type with the Introverted, Intuitive, Thinking, and Prospecting traits. These flexible thinkers enjoy taking an unconventional approach to many aspects of life. They often seek out unlikely paths, mixing willingness to experiment with personal creativity.People with the INTP personality type (Logicians) pride themselves on their unique perspective and vigorous intellect. They can’t help but puzzle over the mysteries of the universe – which may explain why some of the most influential philosophers and scientists of all time have been INTPs. People with this personality type tend to prefer solitude, as they can easily become immersed in their thoughts when they are left to their own devices. They are also incredibly creative and inventive, and they are not afraid to express their novel ways of thinking or to stand out from the crowd. These personality traits can be found in them: The Life of the Mind, Elementary, My Dear Logician, Mysteries of the Universe."
  },
 ENTJ:{
    image:commander,
    label:"Commander",
    category:"Analysts",
    short_description:"Bold, imaginative, and strong-willed, always finding a way – or making one.",
    description:"ENTJ (Commander) is a personality type with the Extraverted, Intuitive, Thinking, and Judging traits. They are decisive people who love momentum and accomplishment. They gather information to construct their creative visions but rarely hesitate for long before acting on them. People with the ENTJ personality type (Commanders) are natural-born leaders. Embodying the gifts of charisma and confidence, ENTJs project authority in a way that draws crowds together behind a common goal. However, these personalities are also characterized by an often ruthless level of rationality, using their drive, determination, and sharp mind to achieve whatever objectives they’ve set for themselves. Their intensity might sometimes rub people the wrong way, but ultimately, ENTJs take pride in both their work ethic and their impressive level of self-discipline. These personality traits can be found in them: Striving for Greatness, A Worthy Challenge. "
  },
  ENTP:{
    image:debator,
    label:"Debater",
    category:"Analysts",
    short_description:"Smart, curious and flexible thinkers who cannot resist an intellectual challenge.",
    description:"ENTP (Debater) is a personality type with the Extraverted, Intuitive, Thinking, and Prospecting traits. They tend to be bold and creative, deconstructing and rebuilding ideas with great mental agility. They pursue their goals vigorously despite any resistance they might encounter.Quick-witted and audacious, people with the ENTP personality type (Debaters) aren’t afraid to disagree with the status quo. In fact, they’re not afraid to disagree with pretty much anything or anyone. Few things light up these personalities more than a bit of verbal sparring – and if the conversation veers into controversial terrain, so much the better.It would be a mistake, though, to think of ENTPs as disagreeable or mean-spirited. Instead, people with this personality type are knowledgeable and curious with a playful sense of humor, and they can be incredibly entertaining. They simply have an offbeat, contrarian idea of fun – one that usually involves a healthy dose of spirited debate.These personality traits can be found in them: Breaking the Rules, The Cost of Contrarianism."
  },
  INFJ:{
    image: advocate,
    label:"Advocate",
    category:"Diplomats",
    short_description:"Quiet visionaries, often serving as inspiring and tireless idealists.",
    description:"INFJ (Advocate) is a personality type with the Introverted, Intuitive, Feeling, and Judging traits. They tend to approach life with deep thoughtfulness and imagination. Their inner vision, personal values, and a quiet, principled version of humanism guide them in all things.Idealistic and principled, people with the INFJ personality type (Advocates) aren’t content to coast through life – they want to stand up and make a difference. For these compassionate personalities, success doesn’t come from money or status but from seeking fulfillment, helping others, and being a force for good in the world.While they have lofty goals and ambitions, INFJs shouldn’t be mistaken for idle dreamers. People with this personality type care about integrity, and they’re rarely satisfied until they’ve done what they know to be right. Conscientious to the core, they move through life with a clear sense of their values, and they aim to never lose sight of what truly matters – not according to other people or society at large but according to their own wisdom and intuition. These personality traits can be found in them: Seeking Purpose, Connecting with Others (and Themselves), A Personal Mission."
  },
  INFP:{
    image:mediator,
    label:"Mediator",
    category:"Diplomats",
    short_description:"Poetic, kind, and altruistic people, always eager to help a good cause.",
    description:"INFP (Mediator) is a personality type with the Introverted, Intuitive, Feeling, and Prospecting traits. These rare personality types tend to be quiet, open-minded, and imaginative, and they apply a caring and creative approach to everything they do.Although they may seem quiet or unassuming, people with the INFP personality type (Mediators) have vibrant, passionate inner lives. Creative and imaginative, they happily lose themselves in daydreams, inventing all sorts of stories and conversations in their mind. INFPs are known for their sensitivity – these personalities can have profound emotional responses to music, art, nature, and the people around them. They are known to be extremely sentimental and nostalgic, often holding onto special keepsakes and memorabilia that brighten their days and fill their heart with joy.Idealistic and empathetic, people with the INFP personality type long for deep, soulful relationships, and they feel called to help others. Due to the fast-paced and competitive nature of our society, they may sometimes feel lonely or invisible, adrift in a world that doesn’t seem to appreciate the traits that make them unique. Yet it is precisely because INFPs brim with such rich sensitivity and profound creativity that they possess the unique potential to connect deeply and initiate positive change.These personality traits can be found in them: The Gift of Empathy, Speaking Their Truth, In Search of a Calling."
  },
  ENFJ:{
    image:protagonist,
    label:"Protagonist",
    category:"Diplomats",
    short_description:"Charismatic and inspiring leaders, able to mesmerize their listeners.",
    description:"ENFJ (Protagonist) is a personality type with the Extraverted, Intuitive, Feeling, and Judging traits. These warm, forthright types love helping others, and they tend to have strong ideas and values. They back their perspective with the creative energy to achieve their goals.People with the ENFJ personality type (Protagonists) feel called to serve a greater purpose in life. Thoughtful and idealistic, ENFJs strive to have a positive impact on other people and the world around them. These personalities rarely shy away from an opportunity to do the right thing, even when doing so is far from easy. ENFJs are born leaders, which explains why these personalities can be found among many notable politicians, coaches, and teachers. Their passion and charisma allow them to inspire others not just in their careers but in every arena of their lives, including their relationships. Few things bring people with the ENFJ personality type a deeper sense of joy and fulfillment than guiding friends and loved ones to grow into their best selves.These personality traits can be found in them:Speaking Up for What’s Right, Getting Involved, Leading the Way. "
  },
  ENFP:{
image:campaigner,
label:"Campaigner",
category:"Diplomats",
short_description:"Enthusiastic, creative, and sociable free spirits, who can always find a reason to smile.",
description:"ENFP (Campaigner) is a personality type with the Extraverted, Intuitive, Feeling, and Prospecting traits. These people tend to embrace big ideas and actions that reflect their sense of hope and goodwill toward others. Their vibrant energy can flow in many directions.People with the ENFP personality type (Campaigners) are true free spirits – outgoing, openhearted, and open-minded. With their lively, upbeat approach to life, ENFPs stand out in any crowd. But even though they can be the life of the party, they don’t just care about having a good time. These personalities have profound depths that are fueled by their intense desire for meaningful, emotional connections with others.These personality traits can be found in them: The Magic of Everyday Life, Seeking Joy."
  },
  ISTJ:{
    image:logistician,
    label:"Logistician",
    category:"Sentinels",
    short_description:"Practical and fact-minded individuals, whose reliability cannot be doubted.",
    description:"ISTJ (Logistician) is a personality type with the Introverted, Observant, Thinking, and Judging traits. These people tend to be reserved yet willful, with a rational outlook on life. They compose their actions carefully and carry them out with methodical purpose. People with the ISTJ personality type (Logisticians) mean what they say and say what they mean, and when they commit to doing something, they make sure to follow through. With their responsible and dependable nature, it might not be so surprising that ISTJ personalities also tend to have a deep respect for structure and tradition. They are often drawn to organizations, workplaces, and educational settings that offer clear hierarchies and expectations. While ISTJs may not be particularly flashy or attention seeking, they do more than their share to keep society on a sturdy, stable foundation. In their families and their communities, people with this personality type often earn respect for their reliability, their practicality, and their ability to stay grounded and logical in even the most stressful situations. These personality traits can be found in them: A Life of Integrity, Picking Up the Slack."
  },
  ISFJ:{
image:defender,
label:"Defender",
category:"Sentinels",
short_description:"Very dedicated and warm protectors, always ready to defend their loved ones.",
description:"ISFJ (Defender) is a personality type with the Introverted, Observant, Feeling, and Judging traits. These people tend to be warm and unassuming in their own steady way. They’re efficient and responsible, giving careful attention to practical details in their daily lives.In their unassuming, understated way, people with the ISFJ personality type (Defenders) help make the world go round. Hardworking and devoted, these personalities feel a deep sense of responsibility to those around them. ISFJs can be counted on to meet deadlines, remember birthdays and special occasions, uphold traditions, and shower their loved ones with gestures of care and support. But they rarely demand recognition for all that they do, preferring instead to operate behind the scenes.This is a capable, can-do personality type with a wealth of versatile gifts. Though sensitive and caring, ISFJs also have excellent analytical abilities and an eye for detail. And despite their reserve, they tend to have well-developed people skills and robust social relationships. These personalities are truly more than the sum of their parts, and their varied strengths shine in even the most ordinary aspects of their daily lives. These personality traits can be found in them:The Gift of Loyalty, The Highest of Standards, Showing Up for Others – and Themselves."
  },
  ESTJ:{
    image:executive,
    label:"Executive",
    category:"Sentinels",
    short_description:"Excellent administrators, unsurpassed at managing things – or people.",
    description:"ESTJ (Executive) is a personality type with the Extraverted, Observant, Thinking, and Judging traits. They possess great fortitude, emphatically following their own sensible judgment. They often serve as a stabilizing force among others, able to offer solid direction amid adversity. People with the ESTJ personality type (Executives) are representatives of tradition and order, utilizing their understanding of what is right, wrong, and socially acceptable to bring families and communities together. Embracing the values of honesty and dedication, ESTJs are valued for their mentorship mindset and their ability to create and follow through on plans in a diligent and efficient manner. They will happily lead the way on difficult paths, and they won’t give up when things become stressful.These personality traits can be found in them: Leading by Example, A Greater Responsibility."
  },
  ESFJ:{
    image:consul,
    label:"Consul",
    category:"Sentinels",
    short_description:"Extraordinarily caring, social and popular people, always eager to help.",
    description:"ESFJ (Consul) is a personality type with the Extraverted, Observant, Feeling, and Judging traits. They are attentive and people-focused, and they enjoy taking part in their social community. Their achievements are guided by decisive values, and they willingly offer guidance to others. For people with the ESFJ personality type (Consuls), life is sweetest when it’s shared with others. These social individuals form the bedrock of many communities, opening their homes – and their hearts – to friends, loved ones, and neighbors.This doesn’t mean that they are saints or that they like everyone. In fact, they are much more likely to form close bonds with people who share their same values and opinions. But regardless of other people’s beliefs, ESFJ personalities still strongly believe in the power of hospitality and good manners, and they tend to feel a sense of duty to those around them. Generous and reliable, they often take it upon themselves – in ways both large and small – to hold their families and their communities together.These personality traits can be found in them: The Beauty of a Responsible Life, Building Relationships That Last."
  },
  ISTP:{
    image:virtuoso,
    label:"Virtuoso",
    category:"Explorers",
    short_description:"Bold and practical experimenters, masters of all kinds of tools.",
    description:"ISTP (Virtuoso) is a personality type with the Introverted, Observant, Thinking, and Prospecting traits. They tend to have an individualistic mindset, pursuing goals without needing much external connection. They engage in life with inquisitiveness and personal skill, varying their approach as needed.People with the ISTP personality type (Virtuosos) love to explore with their hands and their eyes, touching and examining the world around them with an impressive diligence, a casual curiosity, and a healthy dose of skepticism. They are natural makers, moving from project to project, building the useful and the superfluous for the fun of it and learning from their environment as they go. They find no greater joy than in getting their hands dirty pulling things apart and putting them back together, leaving them just a little bit better than they were before. ISTPs prefer to approach problems directly, seeking straightforward solutions over convoluted troubleshooting methods. People with this personality type rely heavily on firsthand experience and trial and error as they execute their ideas and projects. And as they do so, they usually prefer to work at their own pace, on their own terms, and without unnecessary interruptions. This is not a type who is inclined to socialize beyond what is necessary as they try to accomplish their goals. In fact, ISTP personalities generally find regular socializing to be taxing. And when they do decide to get together with people, they will almost always choose smaller, more meaningful interactions over superficial networking. These personality traits can be found in them: Direct & Decisive, Defying the Rules."
  },
  ISFP:{
    image:adventurer,
    label:"Adventurer",
    category:"Explorers",
    short_description:"Flexible and charming artists, always ready to explore and experience something new.",
    description:"ISFP (Adventurer) is a personality type with the Introverted, Observant, Feeling, and Prospecting traits. They tend to have open minds, approaching life, new experiences, and people with grounded warmth. Their ability to stay in the moment helps them uncover exciting potentials. People with the ISFP personality type (Adventurers) are true artists – although not necessarily in the conventional sense. For these types, life itself is a canvas for self-expression. From what they wear to how they spend their free time, they act in ways that vividly reflect who they are as unique individuals. With their exploratory spirit and their ability to find joy in everyday life, ISFPs can be among the most interesting people you’ll ever meet. Driven by their sense of fairness and their open-mindedness, people with this personality type move through life with an infectiously encouraging attitude. They love motivating those close to them to follow their passions and usually follow their own interests with the same unhindered enthusiasm. The only irony? Unassuming and humble, ISFPs tend to see themselves as “just doing their own thing,” so they may not even realize how remarkable they really are. These personality traits can be found in them: The Beauty of an Open Mind, Living in Harmony."
  },
  ESTP:{
    image:entrepreneur,
    label:"Entrepreneur",
    category:"Explorers",
    short_description:"Smart, energetic and very perceptive people, who truly enjoy living on the edge.",
    description:"ESTP (Entrepreneur) is a personality type with the Extraverted, Observant, Thinking, and Prospecting traits. They tend to be energetic and action-oriented, deftly navigating whatever is in front of them. They love uncovering life’s opportunities, whether socializing with others or in more personal pursuits. People with the ESTP personality type (Entrepreneurs) are vibrant individuals brimming with an enthusiastic and spontaneous energy. They tend to be on the competitive side, often assuming that a competitive mindset is a necessity in order to achieve success in life. With their driven, action-oriented attitudes, they rarely waste time thinking about the past. In fact, they excel at keeping their attention rooted in their present – so much so that they rarely find themselves fixated on the time throughout the day. Theory, abstract concepts, and plodding discussions about global issues and their implications don’t keep ESTP personalities interested for long. They keep their conversations energetic, with a good dose of intelligence, but they like to talk about what is – or better yet, to just go out and do it. They often leap before they look, fixing their mistakes as they go rather than sitting idle and preparing contingencies and escape clauses.These personality traits can be found in them: Diving Right In, The Path Less Traveled."
  },
  ESFP:{
    image:entertainer,
    label:"Entertainer",
    category:"Explorers",
    short_description:"Spontaneous, energetic and enthusiastic people – life is never boring around them.",
    description:"ESFP (Entertainer) is a personality type with the Extraverted, Observant, Feeling, and Prospecting traits. These people love vibrant experiences, engaging in life eagerly and taking pleasure in discovering the unknown. They can be very social, often encouraging others into shared activities. If anyone is to be found spontaneously breaking into song and dance, it is people with the ESFP personality type (Entertainers). They get caught up in the excitement of the moment and want everyone else to feel that way too. No other type is as generous with their time and energy when it comes to encouraging others, and no other type does it with such irresistible style. These personality traits can be found in them: Living with Passion, A Spontaneous Spirit."
  }

}

const questionsMbti = [
  {
    "question": "How do you feel about trying new activities?",
    "options": [
      "I love trying new things.",
      "I enjoy it occasionally.",
      "I prefer sticking to what I know.",
      "I avoid new activities whenever possible."
    ]
  },
  {
    "question": "When faced with a new idea, how do you usually react?",
    "options": [
      "I’m curious and eager to learn more.",
      "I’m open to it, but need time to think it over.",
      "I tend to dismiss it if it doesn’t fit my views.",
      "I actively avoid anything unfamiliar."
    ]
  },
  {
    "question": "Do you enjoy exploring abstract ideas or philosophies?",
    "options": [
      "Yes, I often think about big concepts and ideas.",
      "Sometimes, if they are related to my interests.",
      "Rarely, I prefer practical topics.",
      "No, I don’t find abstract ideas appealing."
    ]
  },
  {
    "question": "How often do you engage in creative activities?",
    "options": [
      "All the time, I love creating things.",
      "Occasionally, I enjoy creative activities when I can.",
      "Only when necessary or asked to do so.",
      "Never, I don’t like anything creative."
    ]
  },
  {
    "question": "When making decisions, do you rely on your intuition or on facts?",
    "options": [
      "I often rely on my intuition and gut feelings.",
      "I balance intuition with facts.",
      "I mostly rely on facts and data.",
      "I avoid making decisions based on intuition."
    ]
  },
  {
    "question": "How do you manage your time?",
    "options": [
      "I plan everything in advance and stick to a schedule.",
      "I generally follow a plan but leave room for flexibility.",
      "I tend to improvise and go with the flow.",
      "I rarely make plans and just act spontaneously."
    ]
  },
  {
    "question": "How important is it for you to complete tasks ahead of time?",
    "options": [
      "Very important, I always finish early.",
      "I try to finish early when possible.",
      "I usually finish tasks on time.",
      "I often wait until the last minute to finish."
    ]
  },
  {
    "question": "Do you prefer working alone or in a group setting?",
    "options": [
      "I prefer working alone and focusing on my own tasks.",
      "I’m okay with both, depending on the task.",
      "I like working with others when the task is collaborative.",
      "I find working in groups stressful and inefficient."
    ]
  },
  {
    "question": "How often do you double-check your work for errors?",
    "options": [
      "Always, I make sure everything is perfect.",
      "I check it if I think it might be important.",
      "I check sometimes, but not always.",
      "I rarely check and move on quickly."
    ]
  },
  {
    "question": "Do you find it difficult to stick to a plan?",
    "options": [
      "Not at all, I usually stick to my plans.",
      "I can follow plans but sometimes get distracted.",
      "It’s hard, but I manage if I try.",
      "Yes, I tend to avoid following plans."
    ]
  },
  {
    "question": "Do you enjoy socializing with large groups of people?",
    "options": [
      "Yes, I love being around others and making new friends.",
      "I enjoy it sometimes but need breaks.",
      "I prefer smaller gatherings with close friends.",
      "I avoid large social situations whenever possible."
    ]
  },
  {
    "question": "How do you feel about starting conversations with strangers?",
    "options": [
      "I enjoy it and often initiate conversations.",
      "I’m okay with it but only if necessary.",
      "I’m hesitant and prefer others to start.",
      "I avoid talking to strangers as much as I can."
    ]
  },
  {
    "question": "How would you describe your energy level during social events?",
    "options": [
      "I’m energetic and thrive in social situations.",
      "I enjoy them, but I get tired after a while.",
      "I enjoy them but prefer quieter settings.",
      "I often feel drained and avoid socializing."
    ]
  },
  {
    "question": "How do you feel about engaging in group activities?",
    "options": [
      "I love group activities and thrive in a team environment.",
      "I like group activities if they’re fun.",
      "I prefer to do things on my own but will join occasionally.",
      "I avoid group activities and prefer solitude."
    ]
  },
  {
    "question": "Do you find it easy to make new friends?",
    "options": [
      "Yes, I make new friends easily.",
      "I can make friends if the opportunity arises.",
      "I find it challenging but not impossible.",
      "I don’t try to make new friends."
    ]
  },
  {
    "question": "How often do you reflect on your emotions and thoughts?",
    "options": [
      "All the time, I’m very introspective.",
      "Often, I like to think about how I feel.",
      "Sometimes, but I don’t dwell on it.",
      "Rarely, I prefer not to reflect much."
    ]
  },
  {
    "question": "How do you usually respond to conflicts?",
    "options": [
      "I try to resolve them calmly and rationally.",
      "I address them directly but remain diplomatic.",
      "I avoid conflicts whenever possible.",
      "I tend to get emotional or defensive."
    ]
  },
  {
    "question": "When helping others, how do you feel?",
    "options": [
      "I feel fulfilled and energized.",
      "I feel good but need to balance it with my needs.",
      "I help when asked but don’t go out of my way.",
      "I feel drained and avoid helping too often."
    ]
  },
  {
    "question": "Do you enjoy brainstorming solutions to problems?",
    "options": [
      "Yes, I find it stimulating and fun.",
      "Sometimes, if it’s a topic I care about.",
      "Rarely, I prefer straightforward solutions.",
      "No, I don’t enjoy brainstorming."
    ]
  },
  {
    "question": "How do you feel about following rules?",
    "options": [
      "I strictly adhere to rules and guidelines.",
      "I follow them unless they seem unnecessary.",
      "I follow some but prefer flexibility.",
      "I often question or challenge rules."
    ]
  },
  {
    "question": "How do you handle unexpected changes to your plans?",
    "options": [
      "I adapt quickly and easily.",
      "I manage but need a little time to adjust.",
      "I find it challenging but eventually adapt.",
      "I dislike changes and prefer sticking to the plan."
    ]
  },
  {
    "question": "Do you often take the lead in group settings?",
    "options": [
      "Yes, I naturally take charge.",
      "I do if no one else steps up.",
      "I prefer supporting roles over leading.",
      "No, I avoid leadership roles."
    ]
  },
  {
    "question": "When faced with a complex task, how do you approach it?",
    "options": [
      "I break it into smaller, manageable parts.",
      "I dive in and figure it out as I go.",
      "I wait to see if someone else will take charge.",
      "I avoid it if it seems too challenging."
    ]
  },
  {
    "question": "How comfortable are you with taking risks?",
    "options": [
      "Very comfortable, I enjoy risks.",
      "I’m okay with risks if they’re calculated.",
      "I avoid risks unless absolutely necessary.",
      "I dislike risks and prefer staying cautious."
    ]
  },
  {
    "question": "How often do you rely on others for emotional support?",
    "options": [
      "Often, I share my feelings freely.",
      "Sometimes, but only with close friends or family.",
      "Rarely, I prefer handling emotions myself.",
      "Never, I keep my emotions private."
    ]
  },
  {
    "question": "When solving problems, do you rely more on logic or creativity?",
    "options": [
      "Mostly creativity, I think outside the box.",
      "A balance of creativity and logic.",
      "Mostly logic, I stick to practical solutions.",
      "I avoid problem-solving if possible."
    ]
  },
  {
    "question": "How do you feel about setting long-term goals?",
    "options": [
      "I love setting and working toward them.",
      "I set goals but focus more on short-term tasks.",
      "I struggle to set or stick to long-term goals.",
      "I don’t like planning far into the future."
    ]
  },
  {
    "question": "How often do you express gratitude to others?",
    "options": [
      "All the time, I’m very expressive.",
      "Often, when it feels appropriate.",
      "Sometimes, but not regularly.",
      "Rarely, I don’t express gratitude often."
    ]
  },
  {
    "question": "When faced with criticism, how do you react?",
    "options": [
      "I welcome it and use it to improve.",
      "I take it into account but don’t dwell on it.",
      "I find it difficult to handle but try to improve.",
      "I get defensive or upset when criticized."
    ]
  },
  {
    "question": "Do you prefer structured or flexible environments?",
    "options": [
      "I prefer a structured environment.",
      "I like some structure but value flexibility.",
      "I prefer a flexible environment with minimal rules.",
      "I dislike structure and prefer full freedom."
    ]
  },
  {
    "question": "How often do you enjoy competitive situations?",
    "options": [
      "Very often, I love competition.",
      "Sometimes, if it’s friendly and fun.",
      "Rarely, I prefer cooperative settings.",
      "Never, I dislike competition entirely."
    ]
  },
  {
    "question": "Do you feel energized or drained by social interactions?",
    "options": [
      "Energized, I enjoy being around people.",
      "It depends on the type of interaction.",
      "Drained, I need time to recharge afterward.",
      "I actively avoid social interactions."
    ]
  },
  {
    "question": "How do you handle multiple tasks at once?",
    "options": [
      "I multitask effectively and enjoy the challenge.",
      "I manage, but it can get overwhelming.",
      "I prefer focusing on one task at a time.",
      "I avoid multitasking whenever possible."
    ]
  },
  {
    "question": "How often do you take time to relax and unwind?",
    "options": [
      "Daily, I prioritize relaxation.",
      "Often, but I sometimes forget to make time.",
      "Rarely, I struggle to find time for it.",
      "Never, I’m always busy with tasks."
    ]
  },
  {
    "question": "How do you feel about routine activities?",
    "options": [
      "I enjoy routine and find comfort in it.",
      "I like routine but need some variety occasionally.",
      "I find routine boring but manageable.",
      "I dislike routine and avoid it whenever possible."
    ]
  },
  {
    "question": "Do you find yourself daydreaming often?",
    "options": [
      "Yes, I daydream frequently.",
      "Sometimes, when I have free time.",
      "Rarely, I stay focused on the present.",
      "Never, I don’t enjoy daydreaming."
    ]
  },
  {
    "question": "How do you respond to deadlines?",
    "options": [
      "I complete tasks well ahead of deadlines.",
      "I meet deadlines but sometimes procrastinate.",
      "I rush to finish tasks close to the deadline.",
      "I often miss deadlines or struggle to meet them."
    ]
  },
  {
    "question": "Do you find it easy to adapt to new cultures or environments?",
    "options": [
      "Yes, I adapt quickly and enjoy it.",
      "I adapt with some effort but enjoy the experience.",
      "I find it challenging to adapt but try my best.",
      "I avoid new cultures or environments when possible."
    ]
  },
  {
    "question": "How comfortable are you with public speaking?",
    "options": [
      "Very comfortable, I enjoy speaking in public.",
      "Somewhat comfortable, but I get nervous.",
      "Uncomfortable, but I can manage if needed.",
      "Very uncomfortable, I avoid it at all costs."
    ]
  },
  {
    "question": "How do you feel about taking initiative on projects?",
    "options": [
      "I often take initiative and lead.",
      "I take initiative if necessary.",
      "I prefer others to take the lead.",
      "I avoid taking initiative whenever possible."
    ]
  }
];

const questionsBigFive = [
    {
      "question": "I am the life of the party.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I feel little concern for others.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am always prepared.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I get stressed out easily.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I have a rich vocabulary.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I don't like to draw attention to myself.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am interested in people.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I leave my belongings around.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am relaxed most of the time.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I have difficulty understanding abstract ideas.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I start conversations.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am not interested in other people's problems.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I get chores done right away.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I often feel blue.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am full of ideas.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I don't talk a lot.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am not really interested in others.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I like order.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I get upset easily.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I do not have a good imagination.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I enjoy being the center of attention.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am often worried about others' feelings.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I follow a schedule.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I rarely get irritated.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I often try new things.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am quiet around strangers.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I feel others' emotions.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I like to plan things in advance.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I often feel overwhelmed.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I think quickly.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I love excitement and adventure.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I avoid crowds.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I feel comfortable with people.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I get things done in an orderly fashion.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I get annoyed easily.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I enjoy contemplating abstract concepts.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I enjoy social gatherings.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I tend to be pessimistic.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I believe in my abilities.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I take pride in my work.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am not very imaginative.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I enjoy meeting new people.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I like being spontaneous.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I value harmony in relationships.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I avoid confrontation.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I often get anxious about the future.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I enjoy thinking deeply about the world.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I am good at working with others.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I like to keep things in their place.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    },
    {
      "question": "I do not like to take risks.",
      "options": ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"]
    }
  ]
 
  export function PersonalitySection() {
    const [traits, setTraits] = useState<BigFiveTraits>({});
    const [mbtiProfile, setMbtiProfile] = useState<string>("");
    const [mbtiDetails, setMbtiDetails] = useState<{ profile?: string; traits?: string[] }>({});
    const [bigFiveDetails, setBigFiveDetails] = useState<BigFiveTraits>({});
    const [loading, setLoading] = useState(true); 
    const [mbtiTraits, setMbtiTraits] = useState<string[]>([]);
    const [, setIsTestTaken] = useState<{ [key: string]: boolean }>({
      bigFive: false,
      mbti: false,
    });
    const [mbtiData, setMbtiData] = useState([]);
    const [bigVData, setBigVData] = useState({});
    const [isFormVisible, setIsFormVisible] = useState<"bigFive" | "mbti" | null>();
    const userId = localStorage.getItem("userId");
    const [, setData] = useState<JudgmentalAssessments[]>([]); 
  
    useEffect(() => {
      getData(); // Wait for getData to complete
    }, []);
  
    const getData = async () => {
      try {
        const response = await axios.get(`${baseURL}/user/${userId}`);
        setData(response.data.JudgmentalAssessments); 
        setBigVData(response.data.JudgmentalAssessments.answers.bigFive);
        setMbtiData(response.data.JudgmentalAssessments.answers?.mbti);
        setBigFiveDetails(response.data.JudgmentalAssessments.bigFiveScore);
        setMbtiDetails(response.data.JudgmentalAssessments.mbtiScore);
        setLoading(false); // Set loading to false once the data is fetched
      } catch (error) {
        console.log(error);
        setLoading(false); // Set loading to false in case of error
      }
    };
    useEffect(() => {
      if (loading) {
        return; // Prevent triggering handleMbti and handleBigV if still loading
      }
  
      if (mbtiData && Object.keys(mbtiData).length > 0) {
        handleMbti();
      }
      if (bigVData && Object.keys(bigVData).length > 0) {
        handleBigV();
      }
    }, [mbtiData, bigVData, loading]); 
  
    const handleMbti = async () => {
      try {
        const mbtiArray = mbtiData ? Object.values(mbtiData) : [];
        if (mbtiArray.length === 0) return;
  
        const response = await axios.post(`${baseURL}/user/mbti`, mbtiArray);
        const parsedDataResult = parsedData(response.data); 
  
        setMbtiProfile(parsedDataResult.mbtiProfile);
        setMbtiTraits(parsedDataResult.mbtiTraitsList); 
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleBigV = async () => {
      const bigVArray = Object.values(bigVData);
      try {
        const response = await axios.post(`${baseURL}/user/bigV`, bigVArray);
        const parsedDataResult = parsedData(response.data);
        setTraits(parsedDataResult.bigFive);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleFormSubmit = async (answers: Record<string, any>) => {
      const selectedAnswers = Object.keys(answers).reduce((acc, key) => {
        const selectedOption = answers[key];
        if (selectedOption) acc[key] = selectedOption;
        return acc;
      }, {} as Record<string, any>);
    
      const payload = {
        JudgmentalAssessments: {
          answers: selectedAnswers,
          bigFiveScore: Object.values(traits),
          mbtiScore: { profile: mbtiProfile, traits: mbtiTraits },
        },
      };
    
      try {
        // Save data to the database
        await axios.put(`${baseURL}/user/submit-data/${userId}`, payload);
        // Fetch updated data from the database
        const updatedResponse = await axios.get(`${baseURL}/user/${userId}`);
        // Update the state with the latest data
        setData(updatedResponse.data.JudgmentalAssessments);
        setBigVData(updatedResponse.data.JudgmentalAssessments.answers.bigFive);
        setMbtiData(updatedResponse.data.JudgmentalAssessments.answers?.mbti);
        setBigFiveDetails(updatedResponse.data.JudgmentalAssessments.bigFiveScore);
        setMbtiDetails(updatedResponse.data.JudgmentalAssessments.mbtiScore);
    
        // Mark the test as taken
        setIsTestTaken((prev) => ({
          ...prev,
          [isFormVisible === "bigFive" ? "bigFive" : "mbti"]: true,
        }));
    
        // Hide the form
        setIsFormVisible(null);
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    };
    
  
    const parsedData = (responseData: string): ParsedData => {
      const lines: string[] = responseData
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);
  
      const bigFive: BigFiveTraits = {};
      let mbtiProfile = "";
      let mbtiTraitsList: string[] = [];
  
      lines.forEach((line: string) => {
        if (line.startsWith("*")) {
          const [key, value] = line.slice(0).split(":").map((part) => part.trim());
          if (key && value) {
            bigFive[key as keyof BigFiveTraits] = parseInt(value.replace("%", ""), 10);
          }
        } else if (line.startsWith("mbti traits :")) {
          const mbtiData = line.split(":")[1].trim();
          const traits = mbtiData.split(" (")[1].replace(")", "").split(", ");
          mbtiProfile = mbtiData.split(" (")[0];
          mbtiTraitsList = traits;
        }
      });
  
      return { bigFive, mbtiProfile, mbtiTraitsList };
    };
  
    const profileData = 
    (mbtiDetails.profile && MBTI_PROFILES[mbtiDetails.profile]) 
    ? MBTI_PROFILES[mbtiDetails.profile] 
    : (mbtiDetails.traits?.[0] && MBTI_PROFILES[mbtiDetails.traits[0]]) 
    ? MBTI_PROFILES[mbtiDetails.traits[0]] 
    : undefined;
  
    return (
      <section id="personality" className="mb-8 px-4 sm:px-6 lg:px-0">
     <div className="flex flex-col gap-2 mb-6">
  <div className="flex items-center gap-4">
    <div className="bg-purple-100 p-3 rounded-full">
      <Brain className="w-6 h-6 text-purple-600" />
    </div>
    <h2 className="text-2xl font-semibold">Reflect the truth, reveal the potential.</h2>
  </div>
  <p className="text-xs bg-white/25">
    Note: If the test result doesn't appear, take a few deep breaths and try again.
  </p>
</div>

  
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <div className="block md:grid md:grid-cols-2 md:gap-6">
          {/* Swiper Slider for Mobile View */}
          <Swiper
            className="md:hidden"
            pagination={{ clickable: true }}
            spaceBetween={16}
            modules={[Autoplay]} loop={false} autoplay={{delay: 3000,disableOnInteraction: true}}
          >
            {/* Big Five Traits Slide */}
            <SwiperSlide>
              <div className="bg-white p-6 rounded-3xl border border-gray-200 shadow-md relative">
                {Object.keys(bigFiveDetails).length === 0 && (
                  <button
                    className="absolute top-2 right-2 bg-purple-600 text-white px-3 py-1 rounded text-sm"
                    onClick={() => setIsFormVisible("bigFive")}
                  >
                    Take Test
                  </button>
                )}
                <h3 className="text-lg font-semibold mb-4 text-center">Big Five Traits</h3>
                <RadarChart
                  data={Object.fromEntries(
                    Object.entries(bigFiveDetails).filter(([_, value]) => value !== undefined)
                  )}
                  labels={["Openness", "Conscientiousness", "Extraversion", "Agreeableness", "Neuroticism"]}
                  colors={["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"]}
                />
              </div>
            </SwiperSlide>
  
            {/* MBTI Profile Slide */}
            <SwiperSlide>
              <div className="relative flex flex-col items-center bg-gray-50 py-6 px-4 rounded-3xl shadow-md border border-gray-200 max-w-full mx-auto">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">MBTI Profile</h3>
                <h2 className="text-lg font-bold text-gray-600 mb-4">
                  {mbtiDetails.profile || "Take Test To Reveal."}
                </h2>
  
                <div className="flex flex-col items-center space-y-4">
                  <ul className="space-y-2">
                    {mbtiDetails.traits?.map((trait, index) => (
                      <li key={index} className="flex items-center gap-2 text-gray-700">
                        <span className="w-3 h-3 bg-purple-600 rounded-full"></span>
                        <span className="font-medium">
                          <span className="font-bold text-blue-500">{trait.trim().charAt(0)}</span>
                          {trait.trim().slice(1)}
                        </span>
                      </li>
                    ))}
                  </ul>
  
                  {profileData ? (
                    <div className="relative group w-full text-center">
                      <img
                        src={profileData.image}
                        alt={profileData.label}
                        className="w-32 h-32 rounded-full shadow-md mx-auto"
                      />
                      <div className="mt-4">
                        <h3 className="text-lg font-bold text-gray-800">{profileData.label}</h3>
                        <p className="text-sm text-gray-500 mt-1">{profileData.category}</p>
                      </div>
                      <p className="mt-2 text-base text-gray-600">
                        {profileData.short_description || "No description available"}
                      </p>
                      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition duration-300 bg-white shadow-lg rounded-3xl p-4 w-[98%] max-h-80 overflow-y-auto">
                      <h4 className="font-semibold text-gray-800 mb-2">Description</h4>
                      <p className="text-sm text-gray-600">{profileData.description || "No additional information available."}</p>
                    </div>
                    </div>
                  ) : (
                    <div className="text-center text-gray-500">MBTI Profile not available</div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
  
          {/* Desktop View */}
          <div className="hidden lg:grid lg:grid-cols-2 lg:gap-6 lg:w-[200%]">
            {/* Big Five Traits */}
            <div className="bg-white p-6 rounded-3xl border border-gray-200 shadow-md relative">
            {Object.keys(bigFiveDetails).length === 0 && (
                <button
                  className="absolute top-2 right-2 bg-purple-600 text-white px-3 py-1 rounded text-sm"
                  onClick={() => setIsFormVisible("bigFive")}
                >
                  Take Test
                </button>
              )}
              <h3 className="text-lg font-semibold mb-4 text-center">Big Five Traits</h3>
              <RadarChart
                data={Object.fromEntries(
                  Object.entries(bigFiveDetails).filter(([_, value]) => value !== undefined)
                )}
                labels={["Openness", "Conscientiousness", "Extraversion", "Agreeableness", "Neuroticism"]}
                colors={["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"]}
              />
            </div>
  
            {/* MBTI Profile */}
            <div className="relative flex flex-col items-center bg-gray-50 py-6 px-4 rounded-3xl shadow-md border border-gray-200 max-w-full mx-auto">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">MBTI Profile</h3>
              <h2 className="text-lg font-bold text-gray-600 mb-4">
                {mbtiDetails.profile || "Take Test To Reveal."}
              </h2>
  
              <div className="flex flex-col items-center space-y-4">
                <ul className="space-y-2">
                  {mbtiDetails.traits?.map((trait, index) => (
                    <li key={index} className="flex items-center gap-2 text-gray-700">
                      <span className="w-3 h-3 bg-purple-600 rounded-full"></span>
                      <span className="font-medium">
                        <span className="font-bold text-blue-500">{trait.trim().charAt(0)}</span>
                        {trait.trim().slice(1)}
                      </span>
                    </li>
                  ))}
                </ul>
  
                {profileData ? (
                  <div className="relative group w-full text-center">
                    <img
                      src={profileData.image}
                      alt={profileData.label}
                      className="w-32 h-32 rounded-full shadow-md mx-auto"
                    />
                    <div className="mt-4">
                      <h3 className="text-lg font-bold text-gray-800">{profileData.label}</h3>
                      <p className="text-sm text-gray-500 mt-1">{profileData.category}</p>
                    </div>
                    <p className="mt-2 text-base text-gray-600">
                      {profileData.short_description || "No description available"}
                    </p>
  
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition duration-300 bg-white shadow-lg rounded-3xl p-4 w-[98%]">
                      <h4 className="font-semibold text-gray-800 mb-2"></h4>
                      <p className="text-sm text-gray-600">{profileData.description}</p>
                    </div>
                  </div>
                ) : (
                  <div className="text-center text-gray-500">MBTI Profile not available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
  
      {isFormVisible && (
        <PersonalityForm
          questionsA={questionsBigFive}
          questionsB={questionsMbti}
          onSubmit={handleFormSubmit}
          initialTestType={isFormVisible}
        />
      )}
    </section>
    );
  }
  
