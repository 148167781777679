import { Sun, Moon, Cloud, Stars } from 'lucide-react';
import type { TimeOfDay } from './utils/timeUtils';

interface SkyBackgroundProps {
  timeOfDay: TimeOfDay;
  position: { x: number; y: number };
}

export function SkyBackground({ timeOfDay, position }: SkyBackgroundProps) {
  const isNight = timeOfDay === 'night';
  const showMoon = timeOfDay === 'night' || timeOfDay === 'dawn';
  const showSun = !isNight && timeOfDay !== 'dawn';

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="stars absolute w-full h-[50%]">
        {(isNight || timeOfDay === 'dusk') && Array.from({ length: 20 }).map((_, i) => (
          <Stars 
            key={i}
            className="absolute text-yellow-200 animate-twinkle"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 50}%`, // Only in top half
              animationDelay: `${Math.random() * 3}s`,
              opacity: isNight ? 0.8 : 0.4,
              width: '16px',
              height: '16px'
            }}
          />
        ))}
      </div>
      
      <div
        className="celestial-body absolute transition-all duration-1000 ease-in-out transform"
        style={{
          left: `${position.x}%`,
          top: `${position.y}%`,
        }}
      >
        {showSun && (
          <Sun className="text-yellow-400 w-8 h-8 sm:w-12 sm:h-12 animate-spin-slow" />
        )}
        {showMoon && (
          <Moon className="text-gray-200 w-8 h-8 sm:w-12 sm:h-12" />
        )}
      </div>

      <div className="clouds">
        {Array.from({ length: 5 }).map((_, i) => (
          <Cloud
            key={i}
            className={`absolute text-white/40 animate-float-${i + 1}`}
            style={{
              left: `${i * 25}%`,
              top: `${5 + Math.random() * 15}%`, // Keep clouds in top portion
              opacity: isNight ? 0.2 : 0.6,
              transform: `scale(${0.8 + Math.random() * 0.4})`, // Slightly smaller clouds
            }}
          />
        ))}
      </div>
    </div>
  );
}