/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Form, Input, Button, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { baseURL } from "../../config";
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const FreelancerEdit = () => {
  const userId = localStorage.getItem('userId');
  const [coverImage, setCoverImage] = useState<any>(null);
  const [profilePic, setProfilePic] = useState<any>(null);
  const navigate = useNavigate();

  const handleCoverImageChange = (info: any) => {
    if (info.fileList.length > 0) {
      setCoverImage(info.fileList);
    }
  };

  const handleProfilePicChange = (info: any) => {
    if (info.fileList.length > 0) {
      setProfilePic(info.fileList);
    }
  };

  const onFinish = async (values: any) => {
    const formData = new FormData();
  
    if (profilePic && profilePic[0]?.originFileObj) {
      formData.append('profilePic', profilePic[0].originFileObj);
    }
    if (coverImage && coverImage[0]?.originFileObj) {
      formData.append('coverImage', coverImage[0].originFileObj);
    }
  
    formData.append('designation', values.designation);
    formData.append('bio', values.bio);
    formData.append('experience', values.experience);
    formData.append('hobbies', values.hobbies);
  
    // Log the values to debug the input
    // console.log("Raw interests value:", values.interests);
  
    // Split the comma-separated string into an array and trim any extra spaces
    const interestsArray = values.interests ? values.interests.split(',').map((interest: string) => interest.trim()) : [];
    
    // Log the resulting array to verify it's correctly formatted
    // console.log("Processed interests array:", interestsArray);
  
    formData.append('interests', interestsArray);
    formData.append('globalProblem', values.globalProblem);
  
    const socialLinks = {
      facebook: values.facebook || '',
      instagram: values.instagram || '',
      linkedIn: values.linkedIn || '',
      twitter: values.twitter || '',
      github: values.github || '',
      youtube: values.youtube || '',
    };
    formData.append('socials', JSON.stringify(socialLinks));

  
    try {
      // Log formData to check what’s being sent
      for (const pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }
  
      await axios.put(
        `${baseURL}/user/updateDetails/${userId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      notification.success({
        message: 'Update Successful',
        description: 'Your profile details have been updated successfully!',
        duration: 3,
      });
  
      setTimeout(() => {
        navigate('/profile');
      }, 3000);
    } catch (error: any) {
      notification.error({
        message: 'Update Failed',
        description: error.response?.data?.message || 'An error occurred while updating your profile. Please try again.',
        duration: 3,
      });
    }
  };
  
//   const handleLinkedIn=()=>{
// window.open('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86jfplhre7hkcc&redirect_uri=https://www.swanirbhar.in&scope=w_member_social')
//   }

  return (
    <div className="container p-8">
      <Form onFinish={onFinish} layout="vertical">
        {/* Profile Picture */}
        <Form.Item label="Profile Picture" name="profilePic">
          <Upload beforeUpload={() => false} maxCount={1} listType="picture" onChange={handleProfilePicChange}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        {/* Cover Image */}
        <Form.Item label="Cover Image" name="coverImage">
          <Upload beforeUpload={() => false} maxCount={1} listType="picture" onChange={handleCoverImageChange}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        {/* Designation */}
        <Form.Item
          label="Designation"
          name="designation"
          rules={[{ required: true, message: 'Please input your designation!' }]}
        >
          <Input />
        </Form.Item>

        {/* Bio */}
        <Form.Item
          label="Bio"
          name="bio"
          rules={[{ required: true, message: 'Please input your bio!' }]}
        >
          <TextArea rows={4} />
        </Form.Item>

        {/* Experience */}
        <Form.Item
          label="Experience"
          name="experience"
          rules={[{ required: true, message: 'Please input your experience!' }]}
        >
          <Input />
        </Form.Item>

        {/* Hobbies */}
        <Form.Item
          label="Hobbies"
          name="hobbies"
          rules={[{ required: true, message: 'Please input your hobbies!' }]}
        >
          <Input />
        </Form.Item>

        {/* Interests */}
        <Form.Item
          label="Interests"
          name="interests"
          rules={[{ required: true, message: 'Please input your interests!' }]}
        >
          <Input placeholder='Input your interests, separated by comma' />
        </Form.Item>

        {/* Global Problem */}
        <Form.Item
          label="Global Problem"
          name="globalProblem"
          rules={[{ required: true, message: 'Please input a global problem you are passionate about!' }]}
        >
          <Input />
        </Form.Item>

        {/* Social Links */}
        <Form.Item label="Facebook" name="facebook">
          <Input placeholder="Enter your Facebook profile link" />
        </Form.Item>
        <Form.Item label="Instagram" name="instagram">
          <Input placeholder="Enter your Instagram profile link" />
        </Form.Item>
        <Form.Item label="LinkedIn" name="linkedIn">
          {/* <button onClick={handleLinkedIn} className='bg-gray-400 px-3 py-1 rounded-lg'>Link</button> 
          */}
          <Input placeholder="Enter your LinkedIn profile link" />
        </Form.Item>
        <Form.Item label="Twitter" name="twitter">
          <Input placeholder="Enter your Twitter profile link" />
        </Form.Item>
        <Form.Item label="GitHub" name="github">
          <Input placeholder="Enter your GitHub profile link" />
        </Form.Item>
        <Form.Item label="YouTube" name="youtube">
          <Input placeholder="Enter your YouTube channel link" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FreelancerEdit;
