interface RoadmapQuestionProps {
  question: {
    category: string;
    text: string;
  };
  answer: string;
  onChange: (value: string) => void;
}

export function RoadmapQuestion({ question, answer, onChange }: RoadmapQuestionProps) {
  return (
    <div className="space-y-3">
      <div className="flex flex-col gap-1">
        <span className="text-sm font-medium text-green-600">{question.category}</span>
        <p className="text-gray-700">{question.text}</p>
      </div>
      <textarea
        value={answer}
        onChange={(e) => onChange(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-transparent"
        rows={3}
        placeholder="Enter your answer..."
      />
    </div>
  );
}