import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface ProgramHighlightProps {
  title: string;
  description: string;
  spaceBetween?: number;
  loop?: boolean;
}

export function ProgramHighlight({
  title,
  description,
  spaceBetween = 20,
  loop = false,
}: ProgramHighlightProps) {
  return (
    <Swiper
      spaceBetween={spaceBetween}
      slidesPerView={1} // Ensures only one card is visible
      pagination={{ clickable: true }}
      navigation
      loop={loop} // Optional: Allows slides to loop if set to true
    >
      <SwiperSlide>
        <div className="bg-gray-50 p-4 sm:p-6 lg:p-8 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl max-w-full md:max-w-md lg:max-w-lg mx-auto">
          <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-4 text-gray-800">
            {title}
          </h3>
          <p className="text-sm sm:text-base lg:text-lg text-gray-600">{description}</p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
