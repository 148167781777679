/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { ArrowRight, ArrowLeft, Send } from 'lucide-react';
import { questions } from './questions';

interface HealthAssessment {
  [key: string]: string | string[] | number;
}

interface HealthAssessmentFormProps {
  onSubmit: (data: HealthAssessment) => void;
  onClose: () => void;
}



export function HealthAssessmentForm({ onSubmit, onClose }: HealthAssessmentFormProps) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState<HealthAssessment>({});
  
  const question = questions[currentQuestion];
  const isLastQuestion = currentQuestion === questions.length-1;

  const handleInputChange = (id: keyof HealthAssessment, value: any) => {
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const isArrayValue = (value: any): value is string[] => {
    return Array.isArray(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (formData.weight && formData.height) {
      const weightKg = parseFloat(formData.weight.toString());
      const heightM = parseFloat(formData.height.toString()) / 100;
      const bmi = weightKg / (heightM * heightM);
      formData.bmi = parseFloat(bmi.toFixed(1));
    }
    console.log(formData)

    onSubmit(formData as HealthAssessment);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(prev => prev - 1);
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full mx-auto relative">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        ×
      </button>
      
      <h2 className="text-2xl font-semibold mb-6">Health Assessment</h2>
      <div className="text-sm text-gray-500 mb-8">
        Question {currentQuestion + 1} of {questions.length}
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div key={question.id} className="space-y-2 min-h-[200px]">
          <label className="block text-lg font-medium text-gray-700 mb-4">
            {question.label}
          </label>
          
          {question.type === 'select' && (
            <select
              value={formData[question.id]?.toString() || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 py-2 px-3"
            >
              <option value="">Select an option</option>
              {question.options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
          
          {question.type === 'multiselect' && (
            <div className="space-y-3">
              {question.options?.map((option) => {
                const value = formData[question.id];
                const isChecked = isArrayValue(value) && value.includes(option);
                
                return (
                  <label key={option} className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-md transition-colors">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        const current = isArrayValue(value) ? value : [];
                        const updated = e.target.checked
                          ? [...current, option]
                          : current.filter(item => item !== option);
                        handleInputChange(question.id, updated);
                      }}
                      className="rounded border-gray-300 text-red-600 focus:ring-red-500 w-5 h-5"
                    />
                    <span className="text-gray-700">{option}</span>
                  </label>
                );
              })}
            </div>
          )}
          
          {question.type === 'textarea' && (
            <textarea
              value={formData[question.id]?.toString() || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              placeholder={question.placeholder}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              rows={4}
            />
          )}
          
          {question.type === 'number' && (
            <input
              type="number"
              value={formData[question.id]?.toString() || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              placeholder={question.placeholder}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 py-2 px-3"
            />
          )}
        </div>

        <div className="flex justify-between pt-8">
          <button
            type="button"
            onClick={handlePrevious}
            disabled={currentQuestion === 0}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              currentQuestion === 0
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-white bg-red-600 hover:bg-red-700'
            }`}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Previous
          </button>

          {isLastQuestion ? (
            <button
              type="submit"
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              Submit
              <Send className="w-4 h-4 ml-2" />
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              Next
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          )}
        </div>
      </form>

      <div className="mt-8">
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-red-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
}

export default function App() {
  const handleSubmit = (data: HealthAssessment) => {
    console.log('Form submitted:', data);
  };

  const handleClose = () => {
    console.log('Form closed');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <HealthAssessmentForm onSubmit={handleSubmit} onClose={handleClose} />
    </div>
  );
}