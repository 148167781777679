/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { message, Spin, Menu, Tooltip } from "antd";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import {
  LeftCircleFilled,
  EditOutlined,
  UserOutlined,
  BookOutlined,
  TeamOutlined,
  TrophyOutlined,
  ShoppingOutlined,
  BulbOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import facebook from '../../images/facebook-1.png';
import linkedin from "../../images/linkedin.png";
import github from "../../images/github.png";
import twitter from "../../images/twitter.png";
import youtube from "../../images/youtube.png";
import instagram from "../../images/instagram.jpg"
import PrivateRoute from "../../utils/PrivateRoute";
import { baseURL } from "../../config";
import About from "./About";
import Project from "./Project";
import StartupDetails from "./StartupDetails";
import Products from "./Products";
import Team from "./Team";
import InsightHub from "./InsightHub";
import Forum from "./Forum";
import Badges from "./Badges";
import EntrepreneurEdit from "./EntrepreneurEdit";
import FreelancerEdit from "./FreelancerEdit";

interface User {
  personalDetails: {
    fullName: string;
    dateOfBirth: string;
    emailAddress: string;
    phoneNumber: string;
    cityAndCountry: string;
    gender: string;
    password: string;
    centerCode: string;
    role: string;
  };
  educationAndSkills: {
    educationLevel: string;
    fieldOfStudy: string;
    topSkills: string[];
    certifications: string;
    languages: string;
  };
  interestsAndNiche: {
    interests: string[];
    hobbies: string;
    globalProblem: string;
  };
  personalVisionAndGoals: {
    motivation: string;
    dreamCareer: string;
    shortTermGoals: string[];
    longTermGoals: string[];
  };
  bio: string;
  experience: string;
  skills: string;
  followers: string[];
  designation: string;
  coverImage: string;
  profilePic: string;
  socials: {
    facebook: string;
    linkedIn: string;
    github: string;
    twitter: string;
    youtube: string;
    instagram: string;
  };
}


const Profile = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const location = useLocation();
  const navigate = useNavigate();

  const handleEditClick = () => {
    if (user?.personalDetails.role === "entrepreneur") {
      navigate("/profile/entrepreneur-editInfo");
    } else if (user?.personalDetails.role === "freelancer") {
      navigate("/profile/freelancer-editInfo");
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setUser(response.data);
    } catch (error: any) {
      if (error.response?.data) {
        message.error(error.response.data.message);
      }
    }
    setLoading(false);
  };

  const socialLinks=[
    { platform: "Facebook", url: user?.socials?.facebook, icon: facebook },
    { platform: "Instagram", url: user?.socials?.instagram, icon: instagram },
    { platform: "LinkedIn", url: user?.socials?.linkedIn, icon: linkedin },
    { platform: "GitHub", url: user?.socials?.github, icon: github },
    { platform: "Twitter", url: user?.socials?.twitter, icon: twitter },
    { platform: "YouTube", url: user?.socials?.youtube, icon: youtube },
    
  ]

  const renderMenuItems = () => {
    if (user?.personalDetails.role === "freelancer") {
      return (
        <>
          <Menu.Item
            key="/profile/about"
            icon={<UserOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/about"
              className="text-gray-700 hover:text-blue-600"
            >
              About
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/projects"
            icon={<BookOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/projects"
              className="text-gray-700 hover:text-blue-600"
            >
              Projects
            </Link>
          </Menu.Item>

          <Menu.Item key='/profile/insight-hub' icon = {<BulbOutlined/>} className="px-6">
            <Link to='insight-hub'className="text-gray-700 hover:text-blue-600">
            Insight-Hub
            </Link>
          </Menu.Item>


          <Menu.Item
            key="/profile/badges"
            icon={<TrophyOutlined />}
            className="px-6"
          >   
            <Link
              to="/profile/badges"
              className="text-gray-700 hover:text-blue-600"
            >
              Badges
            </Link>
          </Menu.Item>
        </>
      );
    } else if (user?.personalDetails.role === "entrepreneur") {
      return (
        <>
          <Menu.Item
            key="/profile/about"
            icon={<UserOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/about"
              className="text-gray-700 hover:text-blue-600"
            >
              About
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/startup-details"
            icon={<BookOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/startup-details"
              className="text-gray-700 hover:text-blue-600"
            >
              Startup Details
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/products"
            icon={<ShoppingOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/products"
              className="text-gray-700 hover:text-blue-600"
            >
              Products
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/team"
            icon={<TeamOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/team"
              className="text-gray-700 hover:text-blue-600"
            >
              Team
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/insight-hub"
            icon={<BulbOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/insight-hub"
              className="text-gray-700 hover:text-blue-600"
            >
              Insight Hub
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/forum"
            icon={<MessageOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/forum"
              className="text-gray-700 hover:text-blue-600"
            >
              Forum
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/profile/badges"
            icon={<TrophyOutlined />}
            className="px-6"
          >
            <Link
              to="/profile/badges"
              className="text-gray-700 hover:text-blue-600"
            >
              Badges
            </Link>
          </Menu.Item>
        </>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin className="loading-spinner" />
        </div>
      ) : (
        <>
          {/* Cover Image and Navigation */}
<div
  className="relative bg-center bg-no-repeat bg-cover h-[250px] sm:h-[320px] transition-all duration-300"
  style={{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${user?.coverImage})`,
    backgroundPosition: "center",
  }}
>
  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/40"></div>

  <button
    onClick={() => navigate("/")}
    className="absolute top-4 left-4 p-3 text-sm sm:text-base text-white hover:text-blue-100 transition-all duration-200 flex items-center gap-2 rounded-lg hover:bg-white/20 backdrop-blur-sm"
  >
    <LeftCircleFilled className="text-lg sm:text-xl" />
    Dashboard
  </button>

  <button
    onClick={handleEditClick}
    className="absolute top-4 right-4 px-4 sm:px-6 py-2 bg-transparent text-white rounded-lg text-sm sm:text-base hover:bg-blue-400 transition-all duration-200 flex items-center gap-2 backdrop-blur-sm"
  >
    <EditOutlined />
    Edit
  </button>

  {/* Profile Card */}
  <div className="absolute top-[60%] left-[5%] lg:top-[60%] lg:left-1/2 transform lg:-translate-x-1/2 bg-white px-2 lg:px-6 pt-0 lg:pt-16 pb-6 rounded-lg shadow-lg w-[90%] lg:w-1/3 max-w-6xl flex flex-col sm:flex-row items-center sm:items-start gap-4 sm:gap-x-6 z-10 border border-gray-100">
    {/* Profile Image */}
    <div className="relative sm:absolute sm:top-[-40px] sm:left-[20px] w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] rounded-full overflow-hidden border-4 border-white shadow-lg transform translate-y-[-40%] sm:translate-y-0">
      <img
        src={user?.profilePic}
        alt="Profile"
        className="w-full h-full object-cover"
        onError={(e: any) => {
          e.target.src = `https://ui-avatars.com/api/?name=${user?.personalDetails.fullName}&background=random`;
        }}
      />
    </div>

    {/* Profile Info */}
    <div className="text-center sm:text-left flex flex-col items-center sm:items-start gap-2 sm:gap-3">
      <h2 className="text-lg sm:text-xl font-bold text-gray-800">
        {user?.personalDetails.fullName}
      </h2>
      <p className="text-gray-600 text-sm sm:text-base">
        {user?.designation || "Add your headline"}
      </p>
      
      {/* Social Icons */}
      <div className="flex justify-center sm:justify-start gap-3 mt-2 sm:mt-3">
        {socialLinks.map((social, idx) => (
          <Tooltip title={`${social.platform}`} key={`${social.platform}-${idx}`}>
            <a
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-full border bg-white hover:bg-gray-100 transition-all"
            >
              <img src={social.icon} alt={social.platform} className="w-4 h-4 sm:w-6 sm:h-6" />
            </a>
          </Tooltip>
        ))}
      </div>
    </div>

    {/* Stats */}
    <div className="flex flex-wrap justify-center sm:justify-start gap-4 w-full sm:w-auto">
      <div className="text-center">
        <span className="block text-xl font-bold text-gray-700">0</span>
        <span className="text-xs sm:text-sm font-medium text-gray-500">Courses</span>
      </div>
      <div className="text-center">
        <span className="block text-xl font-bold text-gray-700">{user?.followers.length || 0}</span>
        <span className="text-xs sm:text-sm font-medium text-gray-500">Followers</span>
      </div>
    </div>
  </div>
</div>



          {/* Menu and Content */}
          <div className="mt-48 sm:mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <Menu
              mode="horizontal"
              selectedKeys={[location.pathname]}
              className="mb-8 font-medium border-none shadow-lg rounded-xl bg-white px-6 py-1"
              style={{
                lineHeight: "52px",
              }}
            >
              {renderMenuItems()}
            </Menu>
            <div className="bg-white mt-6 p-6 rounded-xl shadow-sm">
              <Routes>
                <Route
                  path="about"
                  element={
                    <PrivateRoute>
                      <About />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="projects"
                  element={
                    <PrivateRoute>
                      <Project />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="startup-details"
                  element={
                    <PrivateRoute>
                      <StartupDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="products"
                  element={
                    <PrivateRoute>
                      <Products />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="team"
                  element={
                    <PrivateRoute>
                      <Team />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="insight-hub"
                  element={
                    <PrivateRoute>
                      <InsightHub />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="forum"
                  element={
                    <PrivateRoute>
                      <Forum />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="badges"
                  element={
                    <PrivateRoute>
                      <Badges />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="entrepreneur-editInfo"
                  element={
                    <PrivateRoute>
                      <EntrepreneurEdit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="freelancer-editInfo"
                  element={
                    <PrivateRoute>
                      <FreelancerEdit />
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Navigate to="about" replace />} />
              </Routes>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
