/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Button, Checkbox, Form, Input, Typography, message } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../images/logo.png";
import { baseURL } from "../config";

const { Text, Title } = Typography;

type LoginFormProps = {
  onLoginSuccess?: (role: string) => void;
};

type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
  const [form] = Form.useForm<LoginFormData>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (formData: LoginFormData) => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${baseURL}/user/login`, formData);
      message.success(data.message);

      const { token, userId, role } = data;

      // Save user data to localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("role", role);

      // Redirect based on role or navigate to the default dashboard
      onLoginSuccess?.(role) ?? navigate("/dashboard");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred. Please try again.";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col-reverse md:flex-row w-full h-auto md:h-[100vh] lg:h-[90vh]">
      {/* Left Section */}
      <div className="md:w-1/3 bg-[#f0f0f0] p-8 flex flex-col justify-center items-center">
        <img src={logoImage} alt="Logo" className="w-32 mb-4" />
        <h2 className="text-xl font-semibold text-center">Get Started with Swanirbhar</h2>
        <p className="mb-6 text-gray-600 text-center text-sm">
          Join our community of 1L+ Learners
        </p>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="w-full max-w-xs"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input placeholder="Enter Your Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>
          <div className="flex justify-between items-center mb-4">
            <Checkbox>Remember Me</Checkbox>
            <Link to="/forgot-password" className="text-purple-800 font-bold">
              Forgot Password?
            </Link>
          </div>
          <Form.Item>
            <Button
              type="primary"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white"
              loading={loading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
          <div className="text-center mt-4">
            <Text className="text-gray-600">Don't have an account?</Text>
            <Link
              to="https://onboard.swanirbhar.org.in"
              className="text-purple-800 font-bold ml-2"
            >
              Sign Up
            </Link>
          </div>
        </Form>
      </div>

      {/* Right Section */}
      <div className="md:w-2/3 p-8 bg-white flex flex-col">
        <Title level={2} className="text-center lg:text-start">
          Welcome to Swanirbhar
        </Title>
        <Text className="mb-8 text-gray-600 font-semibold text-center lg:text-start">
          Swanirbhar is one of India's largest learner's communities, offering a
          dynamic platform designed to foster personal and academic growth. Join us
          to explore diverse learning opportunities, connect with like-minded
          individuals, and enhance your skills and career prospects.
        </Text>
        <img
          src="https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80"
          alt="Learning Community"
          className="object-contain rounded-2xl w-full h-[72vh]"
        />
      </div>
    </div>
  );
};

export default LoginForm;
