/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../../config";
import { BookOutlined, HeartOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";

const isSectionComplete = (section: Record<string, any>): boolean => {
  return Object.entries(section).every(([key, value]) => {
    if (Array.isArray(value)) {
      return key === "interests" ? true : value.length > 0; // Interests can be empty
    }
    return value !== undefined && value !== null && value.trim() !== ""; // Trim strings to avoid false positives
  });
};


interface User {
  personalDetails: {
    fullName: string;
    dateOfBirth: string;
    emailAddress: string;
    phoneNumber: string;
    cityAndCountry: string;
    gender: string;
    password: string;
    centerCode: string;
  };
  educationAndSkills: {
    educationLevel: string;
    fieldOfStudy: string;
    topSkills: string[];
    certifications: string;
    languages: string;
  };
  interestsAndNiche: {
    interests: string[];
    hobbies: string;
    globalProblem: string;
  }
  bio: string;
  experience: string;
  skills: string;
  designation: string;
}

const About = () => {
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState<User | null>({
    personalDetails: {
      fullName: "",
      dateOfBirth: "",
      emailAddress: "",
      phoneNumber: "",
      cityAndCountry: "",
      gender: "",
      password: "",
      centerCode: "",
    },
    educationAndSkills: {
      educationLevel: "",
      fieldOfStudy: "",
      topSkills: [],
      certifications: "",
      languages: "",
    },
    interestsAndNiche: {
      interests: [],
      hobbies: "",
      globalProblem: "",
    },
    bio: "",
    experience: "",
    skills: "",
    designation: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      getDetails();
    }
  }, [userId]);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data || null); // Ensure data is valid or set to null
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  

  const renderSection = (
    sectionName: string,
    content: React.ReactNode,
    isComplete: boolean
  ) => {
  
    if (!isComplete) {
      return (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700 p-4">
          Please complete your <strong>{sectionName}</strong> section.
        </div>
      );
    }
    return content;
  };

  // Section completion checks
  const isPersonalDetailsComplete = isSectionComplete(data?.personalDetails || {});
  const isEducationAndSkillsComplete = isSectionComplete(data?.educationAndSkills || {});
  const isProfessionalSummaryComplete = !!data?.bio && !!data?.experience;
  const isInterestsAndHobbiesComplete = isSectionComplete(data?.interestsAndNiche || {});

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spin size="large" />
      </div>
    );
  }

  if (!data) {
    return (
      <div className="text-center text-gray-500 min-h-[400px] flex items-center justify-center">
        No data available
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-8 pb-12">
      {/* Personal Information Card */}
      {renderSection(
        "Personal Information",
        <div className="bg-white rounded-2xl shadow-md p-8 space-y-6 border border-gray-100">
       <div className="flex items-center gap-3 mb-6">
          <UserOutlined className="text-2xl text-blue-600" />
          <h2 className="text-2xl font-bold text-gray-800">Personal Information</h2>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Full Name</p>
              <p className="text-base text-gray-800">{data.personalDetails.fullName}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Email Address</p>
              <p className="text-base text-gray-800">{data.personalDetails.emailAddress}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Location</p>
              <p className="text-base text-gray-800">{data.personalDetails.cityAndCountry}</p>
            </div>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Phone Number</p>
              <p className="text-base text-gray-800">{data.personalDetails.phoneNumber}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Date of Birth</p>
              <p className="text-base text-gray-800">{moment(data.personalDetails.dateOfBirth).format("D MMM, YYYY")}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Gender</p>
              <p className="text-base text-gray-800">{data.personalDetails.gender}</p>
            </div>
          </div>
        </div>
        </div>,
        !!isPersonalDetailsComplete
      )}

      {/* Education & Skills Card */}
      {renderSection(
        "Education & Skills",
        <div className="bg-white rounded-2xl shadow-md p-8 space-y-6 border border-gray-100">
         <div className="flex items-center gap-3 mb-6">
          <BookOutlined className="text-2xl text-blue-600" />
          <h2 className="text-2xl font-bold text-gray-800">Education & Skills</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Education Level</p>
              <p className="text-base text-gray-800">{data?.educationAndSkills?.educationLevel}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Field of Study</p>
              <p className="text-base text-gray-800">{data?.educationAndSkills?.fieldOfStudy}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Languages</p>
              <p className="text-base text-gray-800">{data?.educationAndSkills?.languages}</p>
            </div>
          </div>
          <div className="space-y-6">
            <div className="space-y-3">
              <p className="text-sm text-gray-500 font-medium">Top Skills</p>
              <div className="flex flex-wrap gap-2">
                {data?.educationAndSkills?.topSkills.map((skill, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">Certifications</p>
              <p className="text-base text-gray-800">{data?.educationAndSkills?.certifications}</p>
            </div>
          </div>
        </div>
        </div>,
        !!isEducationAndSkillsComplete
      )}

      {/* Bio & Experience Card */}
      {renderSection(
        "Professional Summary",
        <div className="bg-white rounded-2xl shadow-md p-8 space-y-6 border border-gray-100">
         <div className="flex items-center gap-3 mb-6">
          <TeamOutlined className="text-2xl text-blue-600" />
          <h2 className="text-2xl font-bold text-gray-800">Professional Summary</h2>
        </div>

        <div className="space-y-8">
          <div className="space-y-3">
            <p className="text-sm text-gray-500 font-medium">Bio</p>
            <p className="text-base text-gray-800 leading-relaxed">{data?.bio}</p>
          </div>
          
          <div className="space-y-3">
            <p className="text-sm text-gray-500 font-medium">Experience</p>
            <div className="bg-gray-50 rounded-xl p-4">
              <p className="text-base text-gray-800 leading-relaxed">{data?.experience}</p>
            </div>
          </div>

          <div className="space-y-3">
            <p className="text-sm text-gray-500 font-medium">Current Designation</p>
            <p className="text-base text-gray-800">{data?.designation}</p>
          </div>
        </div>
        </div>,
        !!isProfessionalSummaryComplete
      )}



      {/* Interests & Hobbies Card */}
      {renderSection(
        "Interests & Hobbies",
        <div className="bg-white rounded-2xl shadow-md p-8 space-y-6 border border-gray-100">
         <div className="flex items-center gap-3 mb-6">
          <HeartOutlined className="text-2xl text-blue-600" />
          <h2 className="text-2xl font-bold text-gray-800">Interests & Hobbies</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-3">
              <p className="text-sm text-gray-500 font-medium">Interests</p>
              <div className="flex flex-wrap gap-2">
                {data?.interestsAndNiche?.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </div>
            <div className="space-y-3">
              <p className="text-sm text-gray-500 font-medium">Hobbies</p>
              <p className="text-base text-gray-800">{data?.interestsAndNiche?.hobbies}</p>
            </div>
          </div>
          <div className="space-y-6">
            <div className="space-y-3">
              <p className="text-sm text-gray-500 font-medium">Global Problem Focus</p>
              <p className="text-base text-gray-800">{data?.interestsAndNiche?.globalProblem}</p>
            </div>
          </div>
        </div>
        </div>,
        !!isInterestsAndHobbiesComplete
      )}
    </div>
  );
};

export default About;
