
interface QuestionIndicatorsProps {
  totalQuestions: number;
  currentIndex: number;
}

export function QuestionIndicators({ totalQuestions, currentIndex }: QuestionIndicatorsProps) {
  return (
    <div className="flex justify-center space-x-2 mt-2">
      {Array.from({ length: totalQuestions }).map((_, index) => (
        <div
          key={index}
          className={`w-2 h-2 rounded-full ${
            index === currentIndex ? 'bg-yellow-600' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
}