export const questions = [
    {
        "category": "Personal Development",
        "text": "What specific habits or skills would you like to develop over the next 1-3 months?"
      },
      {
        "category": "Personal Development",
        "text": "Are there any personal challenges you aim to address in the short term?"
      },
      {
        "category": "Personal Development",
        "text": "What outcomes would contribute to a greater sense of personal fulfillment within three months?"
      },
      {
        "category": "Professional Growth",
        "text": "What professional objectives are you aiming to achieve in the next 1-3 months?"
      },
      {
        "category": "Professional Growth",
        "text": "Is there a specific certification, training, or skill enhancement you plan to pursue during this period?"
      },
      {
        "category": "Professional Growth",
        "text": "Are there any immediate steps you are taking toward career advancement, such as a promotion or a job transition?"
      },
      {
        "category": "Professional Growth",
        "text": "Do you have any networking goals, such as building professional connections or engaging with mentors?"
      },
      {
        "category": "Financial Planning",
        "text": "Are you working toward any financial goals, such as saving for a specific purpose or improving budgeting practices?"
      },
      {
        "category": "Financial Planning",
        "text": "Do you have plans to address any financial obligations, such as debt reduction or increasing investments, in the near term?"
      },
      {
        "category": "Financial Planning",
        "text": "What financial habits would you like to establish or improve over the next few months?"
      },
      {
        "category": "Health and Wellness",
        "text": "What are your immediate health and fitness goals, such as improving physical activity or dietary habits?"
      },
      {
        "category": "Health and Wellness",
        "text": "Are there specific mental well-being practices, such as mindfulness or stress management, that you plan to implement?"
      },
      {
        "category": "Health and Wellness",
        "text": "Do you have any short-term health milestones, such as completing a medical check-up or initiating a fitness program?"
      },
      {
        "category": "Social and Relationships",
        "text": "Are there any relationships you would like to strengthen or reconnect with in the next three months?"
      },
      {
        "category": "Social and Relationships",
        "text": "What steps are you planning to take to contribute to your community or engage in social activities?"
      },
      {
        "category": "Social and Relationships",
        "text": "Do you have goals for enhancing work-life balance or spending quality time with loved ones?"
      },
      {
        "category": "Learning and Development",
        "text": "What specific topics or areas of knowledge do you want to explore in the short term?"
      },
      {
        "category": "Learning and Development",
        "text": "Are there creative or professional projects you plan to initiate or complete in the next three months?"
      },
      {
        "category": "Learning and Development",
        "text": "What resources, such as books or courses, do you plan to utilize for self-improvement?"
      },
      {
        "category": "Reflection and Vision",
        "text": "How do you envision personal or professional growth during the next three months?"
      },
      {
        "category": "Reflection and Vision",
        "text": "What would success look like for you at the end of this short-term period?"
      },
      {
        "category": "Reflection and Vision",
        "text": "Are there any specific milestones or outcomes you aim to achieve that would provide a sense of accomplishment?"
      },
      {
        "category": "Time Management",
        "text": "What tasks or projects require immediate attention, and how do you plan to address them?"
      },
      {
        "category": "Time Management",
        "text": "What strategies will you use to prioritize your goals over the next 1-3 months?"
      },
      {
        "category": "Motivation",
        "text": "What is the driving motivation behind your short-term objectives?"
      }
  ] as const;