'use client'

import { useState } from "react"
import { Modal } from "antd"

import evolutionary7 from '../../../../images/evolutionary7.svg'
import { CriteriaCard } from "./Criteria"
import { ProgramHighlight } from "./ProgramHighlights"
import { ProcessStep } from "./ProcessStep"

interface ModalContent {
  title: string
  description: string[]
}

export default function Incubation() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ModalContent[]>([])
  const [modalTitle, setModalTitle] = useState<string>('')

  const criteriaData = [
    {
      number: '1',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/57cfe36f8f40a06580db11d535f18c98565900d54ac3074c34bd277c7c5d98da',
      description: "The startup should have a working prototype",
      colorClass: 'bg-blue-500'
    },
    {
      number: '2',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9f79eec10559e2ff88e9957fe86f2e852c0d2d1103496fdb8dc18b084fdcd629',
      description: 'Enrollment in the program is available to students in any academic field.',
      colorClass: 'bg-red-500'
    },
    {
      number: '3',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b19dcf955e235cf126f6c90e177c761793e6b182919c6e2c4bde78026c98c59a',
      description: 'We are only looking for founders from PAN India',
      colorClass: 'bg-yellow-500'
    },
    {
      number: '4',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c0842a85a5c95f029b6c072f56e03410995e7734b9c9eac05a8adbc3095ad56f',
      description: 'If you have graduated, you must have graduated in 2023-24',
      colorClass: 'bg-green-900'
    }
  ]

  const programHighlights = [
    {
      title: "Expert-Led Workshops & Training",
      description: "Learn from successful entrepreneurs and industry experts who will share their insights and experiences with you"
    },
    {
      title: "One-on-One Mentorship",
      description: "Get personalized guidance from seasoned mentors who will help you navigate the challenges of starting and growing a business."
    },
    {
      title: "Access to Funding",
      description: "Discover funding opportunities and pitch your startup to potential investors."
    },
    {
      title: "Incubation & Growth Support",
      description: "Access to incubation resources to help you accelerate your startup's growth."
    },
    {
      title: "Community & Networking",
      description: "Build meaningful connections with like-minded students, industry professionals, and investors"
    }
  ]

  const processSteps = [
    'Application Process',
    'Paid Pre-Incubation (21-Day Activity Program)',
    'Cohort-Based Selection',
    'Post-Selection',
    'Continuous Evaluation & Progression',
  ]

  const handleOpenModal = (content: string) => {
    if (content === 'Application Process') {
      setModalContent([
        {
          title: 'Online Application Form:',
          description: ['Prospective applicants fill out an online form detailing their background, interests, experience, and goals. They will also need to provide information regarding their current business idea (if applicable), career goals, and niche areas they are passionate about.'],
        },
        {
          title: 'Initial Screening:',
          description: [
            'Applications are reviewed to ensure basic eligibility (age, career stage, etc.).',
            'Shortlisted candidates will receive access to the PreIncubation Activity Program.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Paid Pre-Incubation (21-Day Activity Program)') {
      setModalContent([
        {
          title: 'Niche Identification',
          description: [
            'Self-assessment: Applicants undergo personality and skills assessments to discover their core strengths, values, and interests.',
            'Niche Exploration: Interactive modules to explore various niches based on the individual’s passion and potential market opportunities',
            'Mentor CheckIn: Each applicant has a 1:1 virtual session with a mentor (using Eklavya) to discuss their identified niche.'
          ],
        },
        {
          title: 'Idea Development & Validation',
          description: [
            'Problem Identification: Identify a problem in the chosen niche.',
            'Solution Building: Develop a basic business idea or solution to address the problem.',
            'Validation Test: Applicants perform a preliminary validation of their idea by conducting market research or gathering feedback from potential customers.',
            'Weekly Mentor CheckIn: Review findings and refine the idea with mentor guidance.',
          ],
        },
        {
          title: 'Understanding Market and Execution',
          description: [
            'Market Research: Conduct indepth research on the market demand for the proposed solution. ',
            'Competition Analysis: Evaluate competitors and refine the unique value proposition (UVP).',
            'Basic Business Model: Draft a basic business model outlining how the solution will generate revenue and how it will be executed',
            'Final Presentation: Prepare and submit a report summarizing the 21-days experience, including niche identification, idea validation, market research, and potential next steps',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Cohort-Based Selection') {
      setModalContent([
        {
          title: 'Entrepreneur (100/80%)',
          description: [
            'These applicants demonstrated a clear understanding of their niche, validated their idea successfully, and showed readiness to launch a business',
            'Path: Full support for building and scaling their startup (funding, mentorship, business development)',
          ],
        },
        {
          title: 'Founder Team (80/65%)',
          description: [
            'These applicants have a solid idea and niche but need a cofounder or team to help in execution and scaling.',
            'Path: Provided resources to build or join a founding team, with access to mentorship and teambuilding opportunities.',
          ],
        },
        {
          title: 'Freelancer (65/45%)',
          description: [
            'Applicants showed potential but need more time or refinement in their niche or idea development. ',
            'Path: Support to build freelance opportunities within their niche with continued access to niche-specific resources and mentoring.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Post-Selection') {
      setModalContent([
        {
          title: 'Entrepreneur',
          description: [
            'Join the Entrepreneur Incubation Program, receiving 1:1 mentoring, access to funding, business development services, and coworking spaces.',
            'Focus on scaling their business and going to market within 6–12 months',
          ],
        },
        {
          title: 'Founder Team',
          description: [
            'Access to the Founder Development Program, where they can meet other participants to form teams, brainstorm ideas, and validate business concepts with continued mentor support.',
            'Matching with cofounders based on complementary skills.',
          ],
        },
        {
          title: 'Freelancer',
          description: [
            'Enrollment in the Freelancer Growth Program, providing access to learning modules for niche skills, project-building opportunities, and freelance gigs. ',
            'Continued access to mentors and resources to sharpen their niche expertise and develop sustainable freelance businesses.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    else if (content === 'Continuous Evaluation & Progression') {
      setModalContent([
        {
          title: 'Continuous Evaluation & Progression',
          description: [
            'All participants will undergo periodic assessments and mentorship throughout their journey.',
            'Progression from Freelancer to Founder Team or Entrepreneur tracks is possible based on performance and readiness.',
          ],
        },
        {
          title: 'Founder Team',
          description: [
            'Access to the Founder Development Program, where they can meet other participants to form teams, brainstorm ideas, and validate business concepts with continued mentor support.',
            'Matching with cofounders based on complementary skills.',
          ],
        },
        {
          title: 'Freelancer',
          description: [
            'Enrollment in the Freelancer Growth Program, providing access to learning modules for niche skills, project-building opportunities, and freelance gigs. ',
            'Continued access to mentors and resources to sharpen their niche expertise and develop sustainable freelance businesses.',
          ],
        },
      ]);
      setModalTitle(content);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalContent([])
    setModalTitle('')
  }

  return (
<div className="bg-gray-50 min-h-screen">
  <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-900 mb-2">
    21 Days Incubation
  </h2>

  <section className="py-5 sm:py-16 px-4 sm:px-8 lg:px-16 max-w-7xl mx-auto">
    <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-8 text-gray-800">
      Who it is for?
    </h2>

    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
      {criteriaData.map((criteria, index) => (
        <CriteriaCard key={index} {...criteria} />
      ))}
    </div>
  </section>

  <section className="py-10 sm:py-16 px-4 sm:px-8 lg:px-16 max-w-7xl mx-auto bg-white">
    <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-8 text-gray-800">
      Program Highlights
    </h2>

    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-1 gap-6 sm:gap-8">
      {programHighlights.map((highlight, index) => (
        <ProgramHighlight key={index} {...highlight} />
      ))}
    </div>
  </section>

  <section className="py-10 sm:py-16 px-4 sm:px-8 lg:px-16 max-w-7xl mx-auto">
    <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-8 text-gray-800">
      How it works?
    </h2>

    <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
      <div className="lg:w-1/2">
        <img
          src={evolutionary7 || "/placeholder.svg"}
          alt="How it works"
          className="w-1/2 max-w-xs sm:max-w-sm lg:max-w-full mx-auto"
        />
      </div>

      <div className="lg:w-1/2 space-y-4">
        {processSteps.map((step, index) => (
          <ProcessStep
            key={index}
            title={step}
            onClick={() => handleOpenModal(step)}
          />
        ))}
      </div>
    </div>
  </section>

  <Modal
    title={<div style={{ padding: '20px 5px', fontWeight: "700" }}>{modalTitle}</div>}
    open={isModalOpen}
    onCancel={handleCloseModal}
    footer={null}
    width={800}
    style={{ top: '50px' }}
  >
    {modalContent.map((content, index) => (
      <div key={index}  className="p-4   rounded-3xl shadow">
        <h3 className="text-lg font-semibold">{content.title}</h3>
        <ul className="list-disc pl-5 space-y-1">
          {content.description.map((desc, i) => (
            <li key={i}>{desc}</li>
          ))}
        </ul>
      </div>
    ))}
  </Modal>
</div>

  )
}

