/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Button, Drawer, message, Spin } from "antd";
import axios from "axios";
import { baseURL } from "../../config";
import { ArrowLeft } from "lucide-react";
import { formatDistanceToNow } from "date-fns";

interface User {
  personalDetails: {
    fullName: string;
    emailAddress: string;
    cityAndCountry: string;
    role: string;
  };
  followers: string[]; // Array of follower IDs
  profilePic: string;
  coverImage: string;
  socials: {
    facebook: string;
    linkedin: string;
    github: string;
    twitter: string;
    youtube: string;
  };
}

interface Article {
  _id: string;
  title: string;
  category: string;
  content: string;
  coverImage: string;
  createdBy: {
    profilePic?: string;
    fullName: string;
  };
  createdAt: string;
  likes: number;
  comments: Comment[];
  hasLiked: boolean;
}

interface ProfileProps {
  userId: string; // The user ID of the profile being viewed
}

const SearchProfile: React.FC<ProfileProps> = ({ userId }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState<Article[]>([]);
      const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
      const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

  const currentUserId = localStorage.getItem("userId");

  useEffect(() => {
    fetchUserDetails();
    fetchArticles();
  }, [userId]);

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setUser(response.data);
    } catch (error: any) {
      message.error(error.response?.data?.message || "Failed to fetch user details");
    } finally {
      setLoading(false);
    }
  };

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/article/${userId}`);
      setArticleData(response.data.articles);
    } catch (error: any) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    if (!user || !currentUserId) {
      message.error("Unable to update follow status. User ID not found.");
      return;
    }

    try {
      const action = user.followers.includes(currentUserId) ? "unfollow" : "follow";
      const response = await axios.patch(`${baseURL}/user/${userId}/followers`, {
        action,
        currentUserId,
      });

      setUser((prev) => ({
        ...prev!,
        followers: response.data.followers || [],
      }));

      message.success(action === "follow" ? "Followed successfully!" : "Unfollowed successfully!");
    } catch (error: any) {
      console.error("Error updating follow status:", error);
      message.error("Unable to update follow status. Please try again.");
    }
  };


  const openArticleDrawer = (article: Article) => {
      setSelectedArticle(article);
      setDrawerVisible(true);
  };

  const closeArticleDrawer = () => setDrawerVisible(false);

  const isFollowing = currentUserId && user?.followers.includes(currentUserId);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="flex items-center justify-between p-4 bg-white shadow-md sticky top-0 z-10">
        <button className="p-2" onClick={() => window.location.reload()}>
          <ArrowLeft className="w-6 h-6 text-gray-700 hover:text-gray-900" />
        </button>
        <h1 className="text-xl font-semibold text-gray-800">Profile</h1>
      </div>

      {/* Profile Info */}
      <div className="p-4">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <Spin size="large" />
          </div>
        ) : user ? (
          <>
            {/* Cover Image */}
            <div
              className="h-48 w-full bg-cover bg-center rounded-md"
              style={{ backgroundImage: `url(${user.coverImage || "/default-cover.jpg"})` }}
            ></div>

            {/* Profile Image */}
            <div className="relative -mt-14 flex justify-center">
              <img
                src={user.profilePic || "/default-profile.jpg"}
                alt="Profile"
                className="w-28 h-28 rounded-full border-4 border-white shadow-lg"
              />
            </div>

            {/* User Info */}
            <div className="text-center mt-4">
              <h2 className="text-xl font-bold text-gray-800">{user.personalDetails.fullName}</h2>
              <p className="text-sm text-gray-500">{user.personalDetails.cityAndCountry}</p>
            </div>

            {/* Stats */}
            <div className="flex justify-around mt-6">
              <div className="text-center">
                <h3 className="text-lg font-semibold text-gray-800">{articleData.length}</h3>
                <p className="text-sm text-gray-500">Articles</p>
              </div>
              <div className="text-center">
                <h3 className="text-lg font-semibold text-gray-800">{user.followers.length}</h3>
                <p className="text-sm text-gray-500">Followers</p>
              </div>
            </div>

            {/* Follow Button */}
            <div className="mt-4 px-6 flex justify-center">
              <button
                className={`lg:w-52 w-48 py-2 rounded-md text-white font-medium shadow-md transition-all duration-300 hover:opacity-90 ${
                  isFollowing ? "bg-red-500 hover:bg-red-600" : "bg-blue-500 hover:bg-blue-600"
                }`}
                onClick={handleFollow}
              >
                {isFollowing ? "Unfollow" : "Follow"}
              </button>
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500 mt-10">
            <p>User not found.</p>
          </div>
        )}
      </div>

      {/* Articles Section */}
      <div className="relative mt-6 p-10">
        {isFollowing ? (
          <div>
            {articleData.length > 0 ? (
              articleData.map((article) => (
                <div key={article._id} className="mb-4 p-4 bg-white shadow-md rounded-md">
                  <img src={article.coverImage} alt={article.title} />
                  <h3 className="text-lg font-bold text-gray-800">{article.title}</h3>
                  <p className="text-sm text-gray-500">{article.category}</p>
                  <p className="text-gray-700 mt-2">{article.content}</p>
                  <Button
                                type="default"
                                danger
                                onClick={() => openArticleDrawer(article)}
                                className="read-more-button mt-4"
                            >
                                Read More
                            </Button>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No articles from the user yet.</p>
            )}
          </div>
        ) : (
          <div className="absolute inset-0 bg-white/20 bg-opacity-50 flex flex-col justify-center items-center">
            <div className="text-center text-black">
              <span className="text-4xl font-bold">🔒</span>
              <p className="mt-2 text-lg font-medium">Follow the user to see their posts</p>
            </div>
          </div>
        )}
      </div>
      <Drawer title={selectedArticle?.title} placement="right" onClose={closeArticleDrawer} open={drawerVisible} width={600}>
                <div>
                    <img
                        src={selectedArticle?.coverImage}
                        alt="cover"
                        className="w-full h-52 object-cover rounded-lg mb-4"
                    />
                    <p className=" text-base mb-4">{selectedArticle?.content}</p>
                    <div className="flex items-center">
                        <img
                            src={
                                selectedArticle?.createdBy.profilePic
                                    ? `${baseURL}/uploads/profileImages/profilePic/${selectedArticle?.createdBy.profilePic}`
                                    : 'https://via.placeholder.com/130'
                            }
                            className="w-12 h-12 rounded-full mr-4"
                            alt="Profile Pic"
                        />
                        <div>
                            <p className="articleDrawer-author text-sm font-medium">
                                {selectedArticle?.createdBy.fullName}
                            </p>
                            <p className="articleDrawer-date text-xs text-gray-500">
                                {selectedArticle?.createdAt &&
                                    formatDistanceToNow(new Date(selectedArticle.createdAt), { addSuffix: true })}
                            </p>
                        </div>
                    </div>
                </div>
            </Drawer>
    </div>
  );
};

export default SearchProfile;
