import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../config";
import moment from "moment";
import { Button, Drawer, Spin } from "antd";
import { formatDistanceToNow } from "date-fns";
import { debounce } from "lodash";
import { LeftCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MessageSquare, ThumbsUp } from 'lucide-react';

interface Article {
  _id: string;
  title: string;
  category: string;
  content: string;
  coverImage: string;
  createdBy: {
    profilePic?: string;
    fullName: string;
  };
  createdAt: string;
  likes: number;
  comments: Comment[];
  hasLiked: boolean;
}

interface Comment {
  id: string;
  text: string;
  author: string;
  userId:string;
}

const AllBlogs = () => {
  const [getArticles, setGetArticles] = useState<Article[]>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [commentText, setCommentText] = useState('');
  const [userName,setUserName] = useState('');
  const [activeCommentArticle, setActiveCommentArticle] = useState<string | null>(null);
  const userId = localStorage.getItem("userId"); // Replace this with the actual method to fetch the user ID
 
  useEffect(() => {
    getUser()
    fetchArticles(page);
    const handleScroll = debounce(checkScrollPosition, 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  const getUser=async()=>{
try {
  const res=await axios.get(`${baseURL}/user/${userId}`)
  setUserName(res.data.personalDetails.fullName)
} catch (error) {
  console.log(error)
}
  }

  const fetchArticles = async (page: number) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/article?page=${page}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setGetArticles((prev) => [...response.data.articles, ...prev]); // Prepend new articles
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkScrollPosition = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 100 >=
      document.documentElement.scrollHeight
    ) {
      setPage((prev) => prev + 1);
    }
  };

  // Like action (Update likes on both frontend and backend)
  const handleLike = async (articleId: string) => {
    const updatedArticles = getArticles.map((article) => {
      if (article._id === articleId) {
        const updatedArticle = {
          ...article,
          likes: article.hasLiked ? article.likes - 1 : article.likes + 1,
          hasLiked: !article.hasLiked,
        };
        // Call backend to update the like status
        axios.patch(`${baseURL}/article/like/${articleId}`, {
          hasLiked: updatedArticle.hasLiked,
        }, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        return updatedArticle;
      }
      return article;
    });

    setGetArticles(updatedArticles);
  };

  // Add comment action (Update comments on both frontend and backend)
  const handleAddComment = async (articleId: string) => {
    if (!commentText.trim()) return;
  
    if (!userId) {
      console.error("User ID not found. Ensure the user is logged in.");
      return;
    }
  
    const newComment: Comment = {
      id: Math.random().toString(36).substr(2, 9),
      text: commentText,
      author: userName || userId, // Use user's name if available, otherwise userId
      userId: userId,
    };
  
    try {
      // Call backend to add the comment
      await axios.patch(
        `${baseURL}/article/comment/${articleId}`,
        {
          comment: newComment,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
  
      // Update the comments in the article only if the API call is successful
      const updatedArticles = getArticles.map((article) => {
        if (article._id === articleId) {
          return {
            ...article,
            comments: [...article.comments, newComment],
          };
        }
        return article;
      });
  
      setGetArticles(updatedArticles);
      setCommentText('');
      setActiveCommentArticle(null);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  
  
  const openArticleDrawer = (article: Article) => {
    setSelectedArticle(article);
    setDrawerVisible(true);
  };

  const closeArticleDrawer = () => setDrawerVisible(false);

  return (
    <div className="max-w-5xl mx-auto mt-6 space-y-8 px-4">
      <button onClick={() => navigate('/')} className='mb-4 text-2xl'>
        <LeftCircleFilled /> Dashboard
      </button>
      <h2 className="text-2xl font-semibold mb-6">Community Blogs</h2>
      {getArticles?.map((article, index) => (
        <div
          key={index}
          className="bg-gradient-to-r from-white via-gray-100 to-gray-50 shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <img
            src={article.coverImage}
            alt={article.title}
            className="w-full h-64 object-contain bg-white/20"
          />
          <div className="p-4">
            <h2 className="text-2xl font-bold text-gray-800 hover:underline">
              {article.title}
            </h2>
            <p className="text-sm text-gray-500 mt-2">
              {moment(article.createdAt).format("DD MM, YYYY")} • by{" "}
              {article.createdBy ? article.createdBy.fullName : "Anonymous"}
            </p>
            <p className="mt-4 text-gray-700 text-ellipsis overflow-hidden line-clamp-3 mb-4">
              {article.content}
            </p>
          </div>
          <div className="flex justify-between items-center p-4">
            <Button
              type="primary"
              onClick={() => openArticleDrawer(article)}
              className="rounded-full px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white"
            >
              Read More
            </Button>
          </div>
          <div className="flex items-center gap-6 mb-4 justify-center">
            <button
              onClick={() => handleLike(article._id)}
              className={`flex items-center gap-2 ${
                article.hasLiked ? 'text-blue-600' : 'text-gray-600'
              } hover:text-blue-600 transition-colors`}
            >
              <ThumbsUp className="w-5 h-5" />
              <span>{article.likes||0}</span>
            </button>

            <button
              onClick={() => setActiveCommentArticle(
                activeCommentArticle === article._id ? null : article._id
              )}
              className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
            >
              <MessageSquare className="w-5 h-5" />
              <span>{article.comments ? article.comments.length : 0}</span>
            </button>
          </div>

          {activeCommentArticle === article._id && (
            <div className="mt-4">
              <div className="flex gap-2 justify-around">
                <input
                  type="text"
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  placeholder="Add a comment..."
                  className="w-[85%] px-6 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  onClick={() => handleAddComment(article._id)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Comment
                </button>
              </div>

              <div className="mt-4 space-y-3">
  {article?.comments?.map((comment) => (
    <div key={comment.id} className="bg-gray-50 p-3 rounded-lg">
      <div className="font-medium text-sm text-gray-900">
        {/* Check if the comment userId matches the logged-in user's ID */}
        {comment.userId === userId ? userName || "You" : comment.author || comment.userId}
      </div>
      <div className="text-gray-600 mt-1">{comment.text}</div>
    </div>
  ))}
</div>

            </div>
          )}
        </div>
      ))}

      {loading && (
        <div className="flex justify-center mt-8">
          <Spin size="large" />
        </div>
      )}

      <Drawer
        title={selectedArticle?.title}
        placement="right"
        onClose={closeArticleDrawer}
        open={drawerVisible}
        width={600}
        className="drawer-content"
      >
        {selectedArticle && (
          <div>
            <img
              src={selectedArticle.coverImage}
              alt="cover"
              className="w-full h-52 object-cover rounded-lg mb-4"
            />
            <p className="text-base mb-4">{selectedArticle.content}</p>
            <div className="flex items-center">
              <img
                src={
                  selectedArticle.createdBy
                    ? selectedArticle.createdBy.profilePic
                    : "https://via.placeholder.com/130"
                }
                className="w-12 h-12 rounded-full mr-4"
                alt="Profile Pic"
              />
              <div>
                <p className="text-sm font-medium">
                  {selectedArticle.createdBy ? selectedArticle.createdBy.fullName : "Anonymous"}
                </p>
                <p className="text-xs text-gray-500">
                  {selectedArticle.createdAt &&
                    formatDistanceToNow(new Date(selectedArticle.createdAt), {
                      addSuffix: true,
                    })}
                </p>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default AllBlogs;
